import React, { useEffect, useState } from "react";
import "./App.css";
import { Card, Form, Button, InputGroup, Col, Row } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import sstlogo from "./assests/SST Logo 1.jpg";
import spiralslogo1 from "./assests/Spirals logo 1.jpg";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [error, setError] = useState(false);
  const location = useLocation();

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
    return re.test(String(email).toLowerCase());
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    setEmail(value);
    if (validateEmail(value) || !value) {
      setEmailError("");
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;
    setPassword(value);
    if (value || !value) {
      setPasswordError("");
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    let valid = true;
    setEmailError("");
    setPasswordError("");

    if (!email) {
      setEmailError("Email is required.");
      valid = false;
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email format.");
      valid = false;
    }

    if (!password) {
      setPasswordError("Password is required.");
      valid = false;
    }

    if (!valid) return;

    try {
      const obj = {
        CompanyEmailAddress: email,
        password: password,
      };
      if (password === "SSTAI2024!") {
        setError(false);
        navigate("/SSTAI");
      } else {
        setError(true);
      }
    } catch (error) {
      console.error("Getting error in login", error);
      setEmailError("Login error. Please try again later.");
    }
  };

  return (
    <div
      //   style={{
      //     height: "98vh",
      //     width: "98vw",
      //     // overflow: "hidden"
      //   }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Row style={{ height: "100%" }}>
        <Col
          lg={12}
          md={12}
          sm={12}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          <Card className="noborder shadow-none full-width" style={{}}>
          <Card.Body className="d-flex flex-column align-items-center">
              <h4>Welcome To Lipika</h4>
              <img
                src={sstlogo}
                alt="SSTLogo"
                className="spaced-img"
                style={{ width: "80px", margin: "0 20px" }}
                // style={{ width: "80px" }}
              />
              <Card.Title>
                <h4>Log In</h4>
              </Card.Title>
              <Form style={{ width: "100%" }} onSubmit={handleLogin}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    value={email}
                    isInvalid={!!emailError}
                    placeholder="Enter email"
                    onChange={handleEmailChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {emailError}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Password</Form.Label>
                  <InputGroup hasValidation>
                    <Form.Control
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      isInvalid={!!passwordError}
                      onChange={handlePasswordChange}
                    />
                    <InputGroup.Text onClick={togglePasswordVisibility}>
                      <FontAwesomeIcon
                        icon={showPassword ? faEyeSlash : faEye}
                      />
                    </InputGroup.Text>
                    <Form.Control.Feedback type="invalid">
                      {passwordError}
                    </Form.Control.Feedback>
                  </InputGroup>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicCheckbox">
                  <Form.Check type="checkbox" label="Remember Me" />
                </Form.Group>
                {error && (
                  <p style={{ color: "red" }}>Incorrect Email or Password</p>
                )}

                <Button variant="primary" type="submit" className="w-100">
                  Login
                </Button>
                <br/>
                {/* <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <span>Partners with </span>
                  <img
                    src={spiralslogo1}
                    alt="spiralslogo"
                    style={{ width: "80px" }}
                  />
                </div> */}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Login;
