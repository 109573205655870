import React, { useEffect, useState} from 'react'
import LeftNavBar from '../LeftNavbar/LeftNavBar'
import ChatWindow from '../ChatWindow/ChatWindow'

const LogMonitoring = () => {
  const [chat,setChat] = useState([])
  const [userPrompt, setUserPrompt] = useState()
  const [sendMessage,setSendMessage] = useState(false)
  const [errorMsg, setErrorMessage] = useState([])
  const [index, setIndex] =useState([])

  const data ={
    chat: chat,
    setChat: setChat,
    userPrompt: userPrompt,
    setUserPrompt: setUserPrompt,
    sendMessage: sendMessage,
    setSendMessage: setSendMessage,
    index: index
  }

  console.log("errorMsg", errorMsg)

  const sendRequest = async(userPrompt) =>{
    try{
      console.log(userPrompt)
      if(sendMessage){
        const res = await fetch('http://localhost:5000/log-monitoring',{
          method:'POST',
          headers:{
            'Content-type': 'application/json; charset=UTF-8',
          },
          body:JSON.stringify({
            prompt:userPrompt,
            index: index
          })
        })
        console.log(res)
  
        const data=await res.json()
        //  const data={
        //   "resp": "### Error Log Details:\n\n- **Application Name:** Timesheet\n- **Error Message:** Incorrect date value: '2022-22-01' for column 'hire_date' at row 1\n- **Error Type:** Error\n- **Request Method:** POST\n- **Request Payload:**\n  ```json\n  {\n    \"first_name\": \"John\",\n    \"last_name\": \"Doe\",\n    \"personal_email_id\": \"john.doe@example.com\",\n    \"email_id\": \"john.doe@company.com\",\n    \"mobile_no\": \"+1 123-456-7890\",\n    \"type_of_employment\": \"Full-time\",\n    \"hire_date\": \"2022-22-01\",\n    \"address\": \"123 Main St\",\n    \"emergency_contact_no\": \"+1 987-654-3210\",\n    \"city\": \"Seattle\",\n    \"country\": \"United States\",\n    \"zipcode\": \"98101\",\n    \"state\": \"Washington\"\n  }\n  ```\n- **Request URL:** [https://app-timesheet-be-staging.azurewebsites.net/api/employee_details/basic](https://app-timesheet-be-staging.azurewebsites.net/api/employee_details/basic)\n- **Response Status Code:** 500\n- **Timestamp:** 2024-06-14T07:43:56.3379032Z\n\n### Cause of Error:\nThe date value '2022-22-01' for the 'hire_date' field is incorrect. The month value '22' is invalid as months should be between '01' and '12'.\n\n### Correction:\nThe date format has been corrected to a valid format, and the HTTP POST request was made successfully without any errors.\n\nIf you need further assistance or have any questions, feel free to ask!"
        // }
        setChat([...chat,...data])
      }
    }
    catch(e){
      console.log(e)
    }
  }

  useEffect(()=>{
    sendRequest(userPrompt)
  },[sendMessage])
  return (
    <div className='w-100'>
      <div className='row m-0'>
        <LeftNavBar setErrorMessage={setErrorMessage} setIndex={setIndex}/>
        <ChatWindow data={data}/>
      </div>

    </div>
  )
}

export default LogMonitoring