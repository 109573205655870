import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { FiRefreshCw } from "react-icons/fi";
import { HiMiniSpeakerWave } from "react-icons/hi2";
import axios from "axios";
// import { registerLocaleData } from "@angular/common";
import SweetAlertHelper from "./SweetAlertHelper";
// import { async } from "@angular/core/testing";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMessage,
  faArrowAltCircleRight,
} from "@fortawesome/free-regular-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot, faUser } from "@fortawesome/free-solid-svg-icons";
import {
  faMicrophone,
  faMicrophoneSlash,
} from "@fortawesome/free-solid-svg-icons";
import { useSpeechSynthesis } from "react-speech-kit";
import { faVolumeUp, faPause } from "@fortawesome/free-solid-svg-icons";
import { ApiHelper } from "./api.helper";
import { environment } from "./environment";
import {
  faChevronRight,
  faChevronLeft,
  faClipboard,
} from "@fortawesome/free-solid-svg-icons";
import { saveAs } from "file-saver";
import { format } from "date-fns";
import { openaiEnv } from "./chatbot.env";
import "./SSTAI.css";
// import audioWaveGif from '../../assets/images/Audio Wave GIF.gif'; // Adjust the path as necessary
import sstlogo from "./assests/SSTLogo.jpg";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { usePorcupine } from "@picovoice/porcupine-react";
import spiralslogo from "./assests/Spirals logo 1.jpg"

const SPEECH_KEY = process.env.REACT_APP_SPEECH_KEY;
const SPEECH_REGION = process.env.REACT_APP_SPEECH_REGION;
let demo = "";
let file;
let html_table;
let specialistName = "";
let sub_specialistName = "";
let subSpecialistNames = ([] = []);
let practice_name = "";
let physician_name = "";
let SecondOpinionjson;
let SecondOpinionjson1;
let SecondOpinionjsonObject;
let registeredPhysiciansData;
let qcount;
let patientData;
let Documentjson;
let patientDoc;
let limit;
let pendingReferalOpinionList;
let PendingSecondOpinionjsonObject;
let PendingSecondOpinionjson;
let checkboxtable;
let url;
let summaryDoc;
let audioBlob;
let sumres;
let patient_id;
let UploadVideojsonObject;
let UploadVideojson;
var webkitSpeechRecognition;

let isListening = false;
let fileNames;
let videosum;
let res_analyse;
let r1;
let azureUploadResponse;
let uploadVideoFileUrl;
let uploadVideoFileName;
let sstalex = "";
let patient;
let bill_json;
let uploadFileUrl;
let live_transcript = '';
let UploadPrescriptionObject;
// interface Message {
//   text: string;
//   user: boolean;
// }
// declare global {
//   interface Window {
//     test?: (btnName) => void;
//     demoTest?: (url) => void;
//     printDocumentUrl?: (url) => void;
//     testRespond?: (RecordId) => void;
//     testUpdatedRespond?: (btntxt) => void;
//     testAutoRespond?: (btntext) => void;
//     generatePDF?: (UserId) => void;
//   }
// }

// interface MediaRecorder {
//   state: string;
//   start(): void;
//   stop(): void;
//}
let thread_id = 0;

const SSTAI = () => {
  function capitalizeFirstLetter(str) {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  }

  let empName = localStorage.getItem("loggedInUserName") || "Lilah  Tyler";
  let nameParts = empName.split(" ");
  let firstName = capitalizeFirstLetter(nameParts[0]);
  let lastName = capitalizeFirstLetter(nameParts[nameParts.length - 1]);
  let updatedLoggedInUserName = firstName + " " + lastName;
  const [response, setResponse] = useState("");
  const [textBeforeButtons, SettextBeforeButtons] = useState("");
  const [buttonNames, SetbuttonNames] = useState([]);
  const [buttonClicked, setButtonClicked] = useState(null);
  const [SpecialistList, setSpecialistList] = useState(null);
  const [subSpecialistList, setSubSetSpecialistList] = useState(null);
  const [coeList, setcoeList] = useState(null);
  const [patientDetails, setpatientDetails] = useState(null);
  const [physicianList, setPhysicianList] = useState(null);
  const [dropdownNames, SetDropdownNames] = useState([]);
  const [isfaqbutton, Setisfaqbutton] = useState(false);
  const [isvdobutton, Setisvdobutton] = useState(false);
  const [isGeneralSearch, setIsGeneralSearch] = useState(true);
  const [pageName, setPageName] = useState("");
  const [count, setCount] = useState(0);
  const [isotherQue, SetisOtherQue] = useState(false);
  const [isvideoQue, SetisVideoQue] = useState(false);
  const [isexpbutton, Setisexpbutton] = useState(false);
  const [messagesa, setmessagesa] = useState([
    {
      text: `Hi, Dr. ${lastName}. How can I help you today?`,
      user: false,
    },
  ]);
  const { speak, cancel, speaking, voices } = useSpeechSynthesis();
  const [isSpeechPaused, setIsSpeechPaused] = useState(false);
  const chatContainerRef = useRef(null);
  const lastMessageRef = useRef(null);
  const [documentURLRes, setDocumentURLRes] = useState(null);
  const [clickedButton, setClickedButton] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [pdfUrl, SetPdfUrl] = useState([]);
  const [imageUrl, SetImageUrl] = useState([]);
  const [videoUrl, setVideoUrl] = useState([]);
  const [htmlFormat, setHtmlFormat] = useState([]);
  let pageNumber = sessionStorage.getItem("pageNumber");
  let currentLinkGpt = sessionStorage.getItem("currentLinkGpt");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFile1, setSelectedFile1] = useState(null);
  const [selectedFile2, setSelectedFile2] = useState(null);
  const [selectedFile3, setSelectedFile3] = useState(null);
  const [selectedDateTime, setSelectedDateTime] = useState(null);
  const [answer, setAnswer] = useState("");
  const [threads, setThreads] = useState([]);
  const [last7DaysThreads, setLast7DaysThread] = useState([]);
  const [threadApiCalled, setThreadApiCalled] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [activeclicked, setActiveClicked] = useState(false);
  const [threadButtonClicked, setThreadButtonClicked] = useState(false);
  const [activeThread, setActiveThread] = useState(null);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [isTranscriptOpen, setIsTranscriptOpen] = useState(true);
  const [olderThread, setOlderThread] = useState([]);
  const [todayThread, setTodayThread] = useState([]);
  const [transcript, setTranscript] = useState("");
  const [text, setText] = useState("");
  const [isMicrophoneOn, setIsMicrophoneOn] = useState(false);
  const [summaryValue, setSummaryValue] = useState("");
  const [micflag, setMicFlag] = useState(false);
  const [documentLists, setdocumentLists] = useState(null);
  const [copiedIndex, setCopiedIndex] = useState(null);
  const [isdictationStarted, setIsDictationStarted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const mediaRecorderRef = React.useRef(null);
  //speech to text conversion

  const navigate =useNavigate()

  const [isRecording, setIsRecording] = useState(false);
  const initialTime = 15 * 60; // 15 minutes
  // const [timeLeft, setTimeLeft] = useState<number>(initialTime);
  const [timeLeft, setTimeLeft] = useState(0);
  const [showTimer, setShowTimer] = useState(false);
  const intervalRef = useRef(null);
  const currentDateTime = format(new Date(), "yyyyMMdd_HHmmss");
  const fileName = `${empName}_${currentDateTime}.mp3`;
  const [rules, setRules] = useState([]);
  const [authFile, setAuthFile] = useState("");

  const {
    keywordDetection,
    isLoaded,
    isListening,
    error,
    init,
    start,
    stop,
    release,
  } = usePorcupine();

  const apiKey = process.env.REACT_APP_API_KEY;
  const pvModelPath = process.env.REACT_APP_PV_MODEL_PATH;
  const porcupineKeywordArray = {
    publicPath: `${window.location.origin}/models/Hey-SST_en_wasm_v3_0_0.ppn`,
    label: "Hey SST",
  };
  const [isChatActive, setIsChatActive] = useState(false);
  const speechConfig = useRef(null);
  const audioConfig = useRef(null);
  const recognizer = useRef(null);
  const [recognizingTranscript, setRecTranscript] = useState("");
  const [detectedKeyword, setDetectedKeyword] = useState(null);
  const [isListening1, setIsListening1] = useState(false);

  useEffect(() => {
    init(apiKey, porcupineKeywordArray, {
      publicPath: pvModelPath,
    });
  }, []);
  useEffect(() => {
    console.log("isChatActive:-", isChatActive);
    if (keywordDetection !== null) {
      console.log("Keyword detected: ", keywordDetection.label);
      setDetectedKeyword(keywordDetection.label);
      if (keywordDetection.label === "Hey SST") {
        setIsChatActive(true);
        // getWebkitSpeechRecognition();
      }
    }
  }, [keywordDetection]);
  useEffect(() => {
    if (isLoaded) {
      start(); // Start listening for the keyword when Porcupine is loaded
    }
    return () => {
      stop(); // Stop listening when component unmounts
    };
  }, [isLoaded, start, stop]);

  //**** */
  // useEffect(() => {
  //   speechConfig.current = sdk.SpeechConfig.fromSubscription(
  //     SPEECH_KEY,
  //     SPEECH_REGION
  //   );
  //   speechConfig.current.speechRecognitionLanguage = "en-IN";

  //   audioConfig.current = sdk.AudioConfig.fromDefaultMicrophoneInput();
  //   recognizer.current = new sdk.SpeechRecognizer(
  //     speechConfig.current,
  //     audioConfig.current
  //   );

  //   const processRecognizedTranscript = async (event) => {
  //     const result = event.result;
  //     // console.log("Recognition result:", result);

  //     if (result.reason === sdk.ResultReason.RecognizedSpeech) {
  //       const transcript = result.text;
  //       console.log("Transcript: -->", transcript);
  //       // Call a function to process the transcript as needed
  //       var command = transcript;
  //       console.log("command:-", command);
  //       //   setMyTranscript(transcript);
  //       // setMessages((messages) => [
  //       //   ...messages,
  //       //   { type: "user", message: transcript },
  //       //   { type: "bot", message: "Bot Message" },
  //       // ]);
  //       if (
  //         command.toLowerCase().includes("start dictation") ||
  //         command.toLowerCase().includes("dictation")
  //       ) {
  //         demo = "start dictation";
  //         await handleStaticButton("start dictation");
  //         console.log("context in start", context);
  //         // recognition.start();
  //       } else if (
  //         transcript.toLowerCase().includes("patient name") ||
  //         transcript.toLowerCase().includes("name")
  //       ) {
  //         let keyword = "";
  //         if (transcript.includes("patient name")) {
  //           keyword = "patient name";
  //         } else {
  //           keyword = "name";
  //         }
  //         const index = transcript.indexOf(keyword);

  //         // If the phrase is found, extract the patient's name
  //         let patientName = null;
  //         if (index !== -1) {
  //           patientName = transcript.substring(index + keyword.length).trim();
  //         }
  //         demo = patientName;
  //         console.log("context:-", context);
  //         let nContext = [...context, { role: "user", content: patientName }];
  //         const userMessage = { text: patientName, user: true };
  //         let aiResponse = { text: "Please wait...", user: false };
  //         setmessagesa((prevmessagesa) => [
  //           ...prevmessagesa,
  //           userMessage,
  //           aiResponse,
  //         ]);
  //         //speak({ text: aiResponse.text, voice: voices[4] });
  //         setContext(nContext);
  //         getCompletionFrommessagesa(nContext);
  //         // recognition.start();
  //       }
  //     }
  //   };

  //   const processRecognizingTranscript = (event) => {
  //     const result = event.result;
  //     console.log("Recognition result:", result);
  //     if (result.reason === sdk.ResultReason.RecognizingSpeech) {
  //       const transcript = result.text;
  //       console.log("Transcript: -->", transcript);
  //       // Call a function to process the transcript as needed
  //       setRecTranscript(transcript);
  //     }
  //   };

  //   recognizer.current.recognized = (s, e) => processRecognizedTranscript(e);
  //   recognizer.current.recognizing = (s, e) => processRecognizingTranscript(e);

  //   recognizer.current.startContinuousRecognitionAsync(() => {
  //     console.log("Speech recognition started.");
  //     setIsListening1(true);
  //   });

  //   return () => {
  //     recognizer.current.stopContinuousRecognitionAsync(() => {
  //       setIsListening1(false);
  //     });
  //   };
  // }, [isChatActive]);

  //
  useEffect(() => {
    if (isChatActive) {
      console.log("isChatActive");
      if ("webkitSpeechRecognition" in window) {
        // var recognition = new webkitSpeechRecognition();
        const SpeechRecognition =
          window.speechRecognition || window.webkitSpeechRecognition;
        var recognition = new SpeechRecognition();
        recognition.continuous = true; // Keep listening even after the command is recognized
        recognition.interimResults = true; // We only want final results
        var transcriptionElement = document.getElementsByClassName("command");
        // 3. Handle recognized speech
        recognition.onresult = async function (event) {
          var interimTranscript = "";
          var finalTranscript = "";
          for (var i = event.resultIndex; i < event.results.length; ++i) {
            // Check if the result is final
            if (event.results[i].isFinal) {
              finalTranscript += event.results[i][0].transcript;
              var transcript = event.results[i][0].transcript
                .trim()
                .toLowerCase();
              sstalex = transcript;
              console.log("transcript..", transcript);
              // Check if the recognized text is "start dictation"
              if (transcript.toLowerCase() === "yes start dictation") {
                setIsDictationStarted(true);
                demo = "Yes, Start Dictation";
                console.log("Starting Dictation...");
                let nContext = [
                  ...context,
                  { role: "user", content: "Yes, Start Dictation" },
                ];
                const userMessage = {
                  text: "Yes, Start Dictation",
                  user: true,
                };
                let aiResponse = { text: "Please wait...", user: false };
                setmessagesa((prevmessagesa) => [
                  ...prevmessagesa,
                  userMessage,
                  aiResponse,
                ]);
                // //speak({ text: aiResponse.text, voice: voices[4] });
                setContext(nContext);
                getCompletionFrommessagesa(nContext);
                startRecording();
              }
              // Check if the recognized text is "stop dictation"
              else if (transcript.toLowerCase().includes("stop dictation")) {
                setIsDictationStarted(false);
                demo = "stop dictation";
                console.log("Stopping Dictation...");
                stopRecording();
                // handleStaticButton("Stop Dictation");
                // console.log("Starting Dictation...");
                let nContext = [
                  ...context,
                  { role: "user", content: "stop dictation" },
                ];
                const userMessage = { text: "stop dictation", user: true };
                let aiResponse = { text: "Please wait...", user: false };
                setmessagesa((prevmessagesa) => [
                  ...prevmessagesa,
                  userMessage,
                  aiResponse,
                ]);
                // //speak({ text: aiResponse.text, voice: voices[4] });
                SetbuttonNames([]);
                setContext(nContext);
                getCompletionFrommessagesa(nContext);
                // recognition.start();
              } else if (
                !isdictationStarted &&
                (transcript.startsWith("hey") ||
                  transcript.includes("sst") ||
                  transcript.startsWith("hey sst"))
              ) {
                var command = transcript;
                console.log("Command:", command);
                if (
                  command.toLowerCase().includes("start dictation") ||
                  command.toLowerCase().includes("dictation")
                ) {
                  demo = "start dictation";
                  handleStaticButton("start dictation");
                  // recognition.start();
                } else if (
                  command.toLowerCase().includes("respond expert") ||
                  command.toLowerCase().includes("expert")
                ) {
                  demo = "respond expert 2nd opinion";
                  handleStaticButton("respond expert 2nd opinion");
                  // recognition.start();
                } else if (
                  command.toLowerCase().includes("second opinion") ||
                  command.toLowerCase().includes("opinion")
                ) {
                  demo = "second opinion";
                  handleStaticButton("second opinion");
                  // recognition.start();
                } else if (command.toLowerCase().includes("faq")) {
                  demo = "faq";
                  handleStaticButton("faq");
                  // recognition.start();
                } else if (command.toLowerCase().includes("active patient")) {
                  setClickedButton("patient details");
                  demo = command;
                  handleStaticButton("active patient");
                  // recognition.start();
                } else if (
                  command.toLowerCase().includes("patient details") ||
                  command.toLowerCase().includes("patient") ||
                  command.toLowerCase().includes("details")
                ) {
                  demo = "patient details";
                  handleStaticButton("patient details");
                  // recognition.start();
                } else if (
                  command.toLowerCase().includes("upload video") ||
                  command.toLowerCase().includes("upload") ||
                  command.toLowerCase().includes("video")
                ) {
                  demo = "upload video";
                  handleStaticButton("upload video");
                  // recognition.start();
                } else if (
                  command.toLowerCase().includes("insurance claim") ||
                  command.toLowerCase().includes("insurance") ||
                  command.toLowerCase().includes("claim")
                ) {
                  demo = "insurance claim";
                  handleStaticButton("insurance claim");
                  // recognition.start();
                } else if (
                  command.toLowerCase().includes("conversation") ||
                  command.toLowerCase().includes("new") ||
                  command.toLowerCase().includes("new")
                ) {
                  demo = "Start New Conversation";
                  handleExitChat("Start New Conversation");
                  // recognition.start();
                }
              } else if (!isdictationStarted && (transcript.toLowerCase().includes("show summary") || transcript.toLowerCase().includes("summary"))) {
                demo = "show summary"
                let aiResponse = {
                  text: "Please wait, I am generating summary of dictation...",
                  user: false,
                };
                let userMessage = { text: demo, user: true };
                SetbuttonNames([]);  
                setmessagesa([...messagesa, userMessage, aiResponse]);
                //speak({ text: aiResponse.text ,voice: voices[4]});  
                let videosum = await returnSummary(audioBlob); 
                aiResponse = {
                  text: videosum,
                  user: false,
                };
                userMessage = { text: demo, user: true };
                setmessagesa([...messagesa, userMessage, aiResponse]);
                //speak({ text: aiResponse.text ,voice: voices[4]});  
                send_msg_func(demo, videosum,thread_id)
                questionCount();           
                setIsButtonDisabled((prevState) => !prevState);
                setClickedButton("");                        
                SetShowFileUpload(false);
                setThreadButtonClicked(false);
                setContext(initialContext);
                // setThreadApiCalled(false); 
                // recognition.start();         
              } else if (
                transcript.toLowerCase().includes("patient name") ||
                transcript.toLowerCase().includes("name")
              ) {
                let keyword = "";
                if (transcript.includes("patient name")) {
                  keyword = "patient name";
                } else {
                  keyword = "name";
                }
                const index = transcript.indexOf(keyword);

                // If the phrase is found, extract the patient's name
                let patientName = null;
                if (index !== -1) {
                  patientName = transcript
                    .substring(index + keyword.length)
                    .trim();
                }
                demo = patientName;
                let nContext = [
                  ...context,
                  { role: "user", content: patientName },
                ];
                const userMessage = { text: patientName, user: true };
                let aiResponse = { text: "Please wait...", user: false };
                setmessagesa((prevmessagesa) => [
                  ...prevmessagesa,
                  userMessage,
                  aiResponse,
                ]);
                //speak({ text: aiResponse.text, voice: voices[4] });
                setContext(nContext);
                getCompletionFrommessagesa(nContext);
                // recognition.start();
              }
            } else {
              interimTranscript += event.results[i][0].transcript;
            }
            transcriptionElement[0].innerHTML =
              finalTranscript + interimTranscript;
            // recognition.start();
          }
        };

        // Handle any errors
        recognition.onerror = function (event) {
          console.log("error:", event.error);
          if (event.error === "aborted") {
            // Handle aborted error
            // recognition.start();
          }
          if (event.error === "no-speech") {
            console.log("in no speech", event.error);
            // Handle aborted error
            // setMicFlag(false);
            // recognition.start();
            //speak({ text: "specch restarted", voice: voices[4] });
          }
        };
        // recognition.onend = function() {
        //   console.log("Speech recognition ended. Restarting...");
        //   setTimeout(function() {
        //     console.log("Restarting after delay...");
        //     recognition.start();
        //   }, 100000);  // Add a delay (e.g., 1000 milliseconds) before restarting
        // };
        setTimeout(() => {
          // recognition.stop();
          recognition.start();
          setMicFlag(false);
        }, 20000);

        // 4. Start listening
        recognition.start();
      } else {
        console.log("Speech recognition not supported in this browser.");
      }
    }
  }, [isChatActive]);

  //speech to text conversion
  const startSpeechRecognition = () => {
    setMicFlag(true);
    const recognitions2t = new webkitSpeechRecognition();

    recognitions2t.continuous = true;

    recognitions2t.onstart = () => {
      console.log("Speech recognitions2t started");
      setIsMicrophoneOn(true);
    };

    recognitions2t.onresult = (event) => {
      const result = event.results[event.results.length - 1][0].transcript;
      setTranscript(result);
      console.log("transcript: 12 ", result);
      setResponse((prevResponse) => prevResponse + " " + result);
    };

    recognitions2t.onend = () => {
      console.log("Speech recognitions2t ended");
      setMicFlag(false);
      setIsMicrophoneOn(false);
    };
    setTimeout(() => {
      // recognitions2t.stop();
      setMicFlag(false);
    }, 20000);

    recognitions2t.start();
  };

  const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
  const recognitions = new SpeechRecognition();
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;
      const audioChunks = [];
      let isRecording = true;
      recognitions.continuous = true;
      recognitions.interimResults = true;    // Capture interim results for real-time transcription
      let alertShown = false;
      recognitions.onresult = (event) => {
        const result = event.results[event.resultIndex];
        console.log("Transcription: ", result[0].transcript); // Log the transcription
        // live_transcript.push(result[0].transcript);
        live_transcript =  result[0].transcript;
        console.log("live captions: ", live_transcript);
      };
      mediaRecorder.ondataavailable = (e) => {
        console.log("data available",e.data);
        if (e.data.size > 0) {
          audioChunks.push(e.data);
        }
      };
      // const recordingPromise = new Promise((resolve, reject) => {
      mediaRecorder.onstop = async () => {
        clearTimeout(recordingTimeout);
        audioBlob = new Blob(audioChunks, { type: "audio/wav" });
        try {
          // Save the audio blob locally
          saveLocally(audioBlob);
          uploadToServer(audioBlob);
          // resolve(response); // Resolve the promise with the result
        } catch (error) {
          // reject(error); // Reject the promise if there is an error during upload
        }
      };
      // Set a timeout to stop recording after 2 minutes (120,000 milliseconds)
      const recordingTimeout = setTimeout(() => {
        if (isRecording) {
          recognitions.stop();
          stopRecording();
          if (!alertShown) {
            alert(
              "Your recording has reached a duration of 15 minutes. The recording will be stopped."
            );
            alertShown = true;
            console.log("sumres on alert", sumres);
          }
        }
      }, 900000);

      mediaRecorder.start();
      recognitions.start();
      setIsRecording(true);
      // setIsRecording(true);
      setShowTimer(true);
      // setTimeLeft(initialTime);
      setTimeLeft(0);
      // recognitions.start();
      // Wait for the recordingPromise to be resolved or rejected
      // await recordingPromise;
      // const result = await recordingPromise;
      // return result;
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = () => {
    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state === "recording"
    ) {
      mediaRecorderRef.current.stop();
      recognitions.stop(); 
      setIsRecording(false);
      setShowTimer(false);
    }
  };

  const formatTimeLeft = () => {
    let minutesNum = Math.floor(timeLeft / 60);
    let secondsNum = timeLeft % 60;
    // Convert to string and pad with zeros
    let minutes = String(minutesNum).padStart(2, "0");
    let seconds = String(secondsNum).padStart(2, "0");
    return `${minutes}:${seconds}`;
  };

  const saveLocally = (audioBlob) => {
    saveAs(audioBlob, `${fileName}`);
  };

  const extractpriscriptionjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("[");
    const jsonEndIndex = text.indexOf("]", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    UploadPrescriptionObject = JSON.parse(jsonString);
    console.log("UploadVideojsonObject",UploadPrescriptionObject)
    return UploadPrescriptionObject
  };

  const returnSummary = async (audioBlob) => {
    const formData = new FormData();
    formData.append("audio", audioBlob, fileName);
    try {
      const response = await axios.post(`${OPENAI_API}transcribe`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      console.log("response of prescription", response.data.prescription);
      let responseimg;
      let prescriptionurl;
      const medications = await extractpriscriptionjson(response.data.prescription);
      console.log("medications",medications);
      if(demo === "Show Prescription"){
        console.log("inside Show Prescription")
        let apiurl = sls04baseurl + ApiHelper.name_findUserDemographicGeneralApi;   
        const body = {
          "id": patient_id
        };
      const res = await axios.post(
        // "https://func-dev-medrecord-eastus-004.azurewebsites.net/api/findUserDemographicGeneral",
        `${apiurl}`,
        body
      );
      patientData = res.data.commandResult.data.userInfo
      console.log("patientData", patientData);
      const options = {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };
      const today = new Date().toLocaleDateString('en-US', options);
      let obj = {
        "title": "Prescription Form Preview",
        "header": "PDF PREVIEW FROM TEMPLATE",
        "prescription": {
          "header": "Prescription",
          "number": "0001",
          "date": today
        },
        "patient": {
          "name": `${patientData.from1FirstName} ${patientData.from1LastName}`,
          "phone": patientData.Mobile,
          "email": patientData.Email,
          "address": `${patientData.form1StreetName}, ${patientData.form1City}, ${patientData.form1State}, ${patientData.form1ZIP}`,
          "age": patientData.form1Age,
          "gender": patientData.form1Gender,
          "dob": patientData.form1DOB
        },
         "medications": medications,
          "physician": {
              "name": loggedInUserName,
              "phone": loggedInUserMobile,
              "signature": loggedInUserName,
              "email": loggedInUserEmail
          },
           "pharmacy": {
              "name": " ABC Pharmacy,",
              "phone": "(112) 312-3456",
              "address": "123 Main Street, City.",
              "email": "abc@noemail.com"
          },
          "date": today
      }
      let pdf_payload = {
        template_name: "prescription_template.html",
        data: obj
      }
      let pdf_url = await axios.post(
          // `http://127.0.0.1:5000/generate_pdf`, 
          `https://openaitimesheet.azurewebsites.net/generate_html_to_pdf`,
          pdf_payload,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          }
          );
          return ` 
        <p>To view prescription click on below link:  <a href= ${pdf_url.data.pdf_url} target="_blank"> ${patientData.from1FirstName} ${patientData.from1LastName} Prescription </a></p>
        \n\nDo you need any other help?      
       `
      }
      if (uploadFileUrl !== undefined && uploadFileUrl !== "") {
        // r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", uploadFileUrl);      
        const imageBody2 = {
          img_data: uploadFileUrl,
          question: "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points",
        };
        responseimg = await axios.post(
          `${VERTEX_API}analyze_image`,
          imageBody2
        );
        console.log("responseimg", responseimg)
        return `       
        <b>Autoscribe Summary:\n</b> ${response.data.summary}
        <b>Image Summary:\n</b> ${responseimg.data.answer}\n\n
        <p>To view prescription click on below link:  <a href= ${prescriptionurl} target="_blank"> ${patientData.from1FirstName} ${patientData.from1LastName} Prescription </a></p>
        \n\nDo you need any other help?      
        `;
      }
      return `       
          <b>Autoscribe Summary:\n</b> ${response.data.summary}          
          \n\nDo you need any other help?      
      `;
      // return response.data.summary + "Do you need any other help?";
    } catch (error) {
      console.error("Error in generating summary of audio file:", error);
    }
  };
  const uploadToServer = async (audioBlob) => {
    const formData = new FormData();
    formData.append("audio", audioBlob, fileName);
    try {
      const response = await axios.post(`${OPENAI_API}transcribe`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      if (response) {
        const { original_text_content, summary } = response.data;
        const originalTextBlob = new Blob([original_text_content], {
          type: "text/plain",
        });
        const summaryBlob = new Blob([summary], { type: "text/plain" });

        // Save the Blobs as files
        saveAs(originalTextBlob, `${fileName}_transcription.txt`);
        saveAs(summaryBlob, `${fileName}_summary.txt`);
        const audioFile = new File([audioBlob], `${fileName}.mp3`, {
          type: "audio/mp3",
        });
        const transcriptFile = new File(
          [originalTextBlob],
          `${fileName}_transcription.txt`,
          { type: "text/plain" }
        );
        const summaryFile = new File([summaryBlob], `${fileName}_summary.txt`, {
          type: "text/plain",
        });

        // Create FormData for the second API call
        const secondApiFormData = new FormData();
        secondApiFormData.append("company_slug", "jamiewisser");
        secondApiFormData.append("physician_id", loggedInUserId);
        secondApiFormData.append("patient_id", patient_id);
        secondApiFormData.append("chat_id", `${thread_id}`);
        secondApiFormData.append("audioKey", audioFile);
        secondApiFormData.append("transcriptKey", transcriptFile);
        secondApiFormData.append("summaryKey", summaryFile);
        let apiurlphp = phpbaseurl + ApiHelper.php_uploadRecording;
        let result = await axios.post(
          // "https://api.medrecordexchange.com/dev/uploadRecording.php",
          `${apiurlphp}`,
          secondApiFormData
        );
        url = result.data.commandResult.data;

        const saveDictationUrls = {
          comments: "Dictation",
          loggedInUserId: loggedInUserId,
          patientId: patient_id,
          adminId: loggedInUserId,
          companySlug: "jamiewisser",
          recordingUrl: url.audioUrl,
          summaryUrl: url.summaryUrl,
          transcriptUrl: url.transcriptUrl,
        };

        let apiurl = sls05baseurl + ApiHelper.saveComment;
        let urlResult = await axios.post(`${apiurl}`, saveDictationUrls);
        setSummaryValue(response.data.summary);
      } else {
        console.error("Failed to upload audio file to server.");
      }
    } catch (error) {
      console.error("Error uploading audio file:", error);
    }
  };

  useEffect(() => {
    const fetchBlobData = async () => {
      try {
        const response = await fetch('https://blobtimesheet.blob.core.windows.net/insurancedocs/Denialrules.txt');
        // console.log("response", response)
        const data = await response.text();
        // console.log("data", data)
        const rulesArray = data.split('\n').filter(rule => rule.trim() !== ''); // Remove empty lines
        // console.log("rulesArray", rulesArray, typeof(rulesArray))
        setRules(rulesArray);

        // const jsonObject = JSON.parse(data);
        // console.log("reasonsArray data", jsonObject.denial_reasons)
        // const reasonsArray = jsonObject.denial_reasons;
        // console.log("reasonsArray", reasonsArray)
        // setDenial_reasons(reasonsArray);
      } catch (error) {
        console.error('Error fetching blob data:', error);
      }
    };
    fetchBlobData();
  }, []);
  
  useEffect(() => {
    console.log("Loading state:", isLoading); // Log state whenever it changes
  }, [isLoading]);

  useEffect(() => {
    if (showTimer) {
      intervalRef.current = setInterval(() => {
        // setTimeLeft((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
        setTimeLeft((prevTime) => prevTime + 1);
      }, 1000);
    } else {
      clearInterval(intervalRef.current);
    }

    return () => clearInterval(intervalRef.current);
  }, [showTimer]);

  useEffect(() => {
    if (!isRecording) {
      clearInterval(intervalRef.current);
    }
  }, [isRecording]);

  const toggleTranscript = () =>{
    setIsTranscriptOpen(!isTranscriptOpen);
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const [filename1, setFilename1] = useState(null);
  const [filename2, setFilename2] = useState(null);
  const [filename3, setFilename3] = useState(null);
  const [showFileUpload, SetShowFileUpload] = useState(false);

  useEffect(() => {}, [isSidebarOpen]);

  let loggedInUserId = localStorage.getItem("loggedInUserId") || "891";
  let loggedInUserRoleId = localStorage.getItem("loggedInUserRoleId") || "4";
  let loggedInUserMobile =
    localStorage.getItem("loggedInUserMobile") || "8458978589";
  let formattedPhoneNumber = `(${loggedInUserMobile.slice(
    0,
    3
  )}) ${loggedInUserMobile.slice(3, 6)}-${loggedInUserMobile.slice(6)}`;
  let loggedInUserName = localStorage.getItem("loggedInUserName");
  let doctorToDoctorEmailPendingCount = localStorage.getItem(
    "doctorToDoctorEmailPendingCount"
  );
  let physicianSecondOpinionPendingCount = localStorage.getItem(
    "physicianSecondOpinionPendingCount"
  );
  let patientSecondOpinionPendingCount = localStorage.getItem(
    "patientSecondOpinionPendingCount"
  );
  let expertSecondOpinionPendingCount = localStorage.getItem(
    "expertSecondOpinionPendingCount"
  );
  let currentURL =
    localStorage.getItem("currentURL") ||
    "/doctor/reply-d2d/50181e35-ea2e-49aa-ad72-88d1e2927d37/1909/896/4/6/4";
  let baseURL =
    localStorage.getItem("baseURL") ||
    "https://devjamiewisser.medrecordexchange.com";
  let loggedInUserEmail =
    localStorage.getItem("loggedInUserEmail") || "lilah_tyler@yopmail.com";
  let sls05baseurl = environment.lambdaApiUrl_05;
  let sls03baseurl = environment.lambdaApiUrl_03;
  let sls04baseurl = environment.lambdaApiUrl_04;
  let phpbaseurl = environment.phpApiUrl;
  let envName = environment.environmentName;
  let OPENAI_API = openaiEnv.OPENAI_API;
  let ACS_API = openaiEnv.ACS_API;
  let VERTEX_API = openaiEnv.VERTEX_API;

  const initialContext = [
    {
      role: "system",
      content: `
      You are a friendly physician Assistance ChatBot, first greet the user with their last name Dr.${lastName} (note : take only last name with adding initial as "Dr." and greet).`,
    },
    {
      role: "user",
      content: "Hi",
    },
  ];
  const [context, setContext] = useState(initialContext);
  const hasMessages = (threads) => {
    return true; // Replace with your actual logic
  };

  const handleCopyToClipboard = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => setCopiedIndex(index))
      .catch((err) => console.error("Unable to copy to clipboard", err));
  };

  const handleVolumeIconClick = (text) => () => {
    if (speaking) {
      if (isSpeechPaused) {
        speak({ text: text, voice: voices[4] });
        setIsSpeechPaused(false);
      } else {
        // Pause speech synthesis
        cancel();
        setIsSpeechPaused(true);
      }
    } else {
      // Start speech synthesis
      speak({ text: text, voice: voices[4] });
      setIsSpeechPaused(false);
    }
  };

  const send_msg_func = async (req, respo, id) => {
    const obj2 = {
      request: req,
      response: respo,
      threadId: id,
    };
    const add_msg = await axios.post(`${ACS_API}send_msg`, obj2);
    // const add_msg = await axios.post(`http://127.0.0.1:5000/send_msg`, obj2);
  };

  const categorizeThreads = (threads_list) => {
    try {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      const todayThreads = [];
      const last7DaysThreads = [];
      const olderThreads = [];

      threads_list.forEach((thread) => {
        const threadDate = new Date(thread.date);
        const last7DaysDate = new Date(today);
        last7DaysDate.setDate(today.getDate() - 7);
        if (threadDate >= today) {
          todayThreads.push(thread);
        } else if (threadDate >= last7DaysDate) {
          last7DaysThreads.push(thread);
        } else {
          olderThreads.push(thread);
        }
      });
      todayThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      last7DaysThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      olderThreads.sort(
        (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
      );
      setTodayThread(todayThreads);
      setLast7DaysThread(last7DaysThreads);
      setOlderThread(olderThreads);
      // console.log("Last 7 days: ", last7DaysThreads);
    } catch (error) {
      console.log("Error:-", error);
    }
  };

  const get_all_threads = async () => {
    try {
      const response = await axios.get(`${ACS_API}get_all_threads/${loggedInUserEmail}`);
      // const response = await axios.get(`http://127.0.0.1:5000/get_all_threads/${loggedInUserEmail}`);

      const names = response.data;
      const id = names.id;
      categorizeThreads(names);
      const transformedMessages = names.map((item) => [item.name, item.id]);
      setThreads(transformedMessages);
    } catch (error) {
      console.error("Error: ", error);
    }
  };

  const loadThread = async (thread, index) => {
    handleButtonClick();
    setButtonClicked("Start new Conversation");
    setIsButtonDisabled(false);
    setThreadButtonClicked(true);
    setActiveThread(index);
    console.log("thread id: ", thread);

    try {
      const t_id = thread.id;
      const name = thread.name;
      const response = await axios.get(`${ACS_API}get_full_thread/${t_id}`);
      // const response = await axios.get(`http://127.0.0.1:5000/get_full_thread/${t_id}`);
      const chat = response.data;
      setButtonClicked("Start new Conversation");
      setIsButtonDisabled(false);
      const id = chat.row;
      const transformedMessages = chat.map((item) => {
        const userMsg = { text: item.question, user: true };
        let aiResponse = { text: item.answer, user: false };
        return [userMsg, aiResponse];
      });
      // Flatten the array of arrays into a single array
      const flattenedMessages = [].concat(...transformedMessages);
      try {
        setmessagesa(flattenedMessages);
      } catch (err) {
        console.log("error: ", err);
      }
    } catch (error) {
      console.error("Error: ", error);
    } finally {
      setIsButtonDisabled(false);
    }
  };

  const handleButtonClick = () => {
    setClicked(!clicked);
  };

  const resetAllData = () => {
    SweetAlertHelper.showConfirm(
      "Are you sure want to reset all the data?",
      () => {
        window.location.reload();
      }
    );
  };

  const extractButtonNames = (text) => {
    const startIndex = text.indexOf("Buttons: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      return buttonString.split("', '");
    }
    return [];
  };

  const extractDropdownopt = (text) => {
    const startIndex = text.toLowerCase().indexOf("dropdown: ['") + 11;
    const endIndex = text.indexOf("']", startIndex);
    if (startIndex !== -1 && endIndex !== -1) {
      const buttonString = text.substring(startIndex, endIndex);
      const options = buttonString
        .split("', '")
        .map((option) => option.replace(/'/g, ""));
      return options;
    }
    return [];
  };

  const getAllDocumentsAPI = async () => {
    let apiurl = sls03baseurl + ApiHelper.name_findAllDocumentsApi;
    const body = {
      company_slug: null,
      loggedInUserId: patient_id,
      // physicianId: "E",
    };

    try {
      const res = await axios.post(
        // "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getAllDocumentsList",
        `${apiurl}`,
        body
      );
      const { data } = res; // Use res.data directly
      console.log("Data received:", data);

      const allDocumentsData = res.data.commandResult.data.documentList.map(
        (item) => ({
          RecordId: item.RecordId,
          DocumentName: item.DocumentName,
          DocumentUrl: item.DocumentUrl,
        })
      );
      const allDocumentData = allDocumentsData.slice(0, 10);
      let nContext = [
        ...context,
        { role: "user", content: demo },
        {
          role: "system",
          content: `here are the documents of patient  ${JSON.stringify(
            allDocumentData
          )}`,
        },
      ];
      setContext(nContext);
      setdocumentLists(allDocumentData);
      console.log("allDocumentData...", allDocumentData);
      return allDocumentData;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    initialquestionCount();
    // registeredPhysicians_listapi();
    specialist_listapi();
    // getAllDocumentsAPI();
    get_all_threads();
  }, []);

  const specialist_listapi = async () => {
    let apiurl = sls05baseurl + ApiHelper.getSpecialties;
    try {
      const body = {
        loggedInUserId: loggedInUserId,
      };
      const res = await axios.post(`${apiurl}`, body);
      const { data } = res;
      const specialistData = res.data.commandResult.data.specialities
        .filter((item) => item.is_active === 1)
        .map((item) => ({
          id: item.id,
          speciality: item.speciality,
        }));
      setSpecialistList(specialistData);
      return specialistData;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const subSpecialist_listapi = async (speciality) => {
    let apiurl = sls05baseurl + ApiHelper.getSubSpecialties;
    try {
      const body = {
        speciality: speciality,
      };
      const res = await axios.post(`${apiurl}`, body);
      const { data } = res;
      subSpecialistNames = res.data.commandResult.data.sub_specialities.map(
        (item) => ({
          id: item.id,
          sub_speciality: item.sub_speciality,
        })
      );
      setSubSetSpecialistList(subSpecialistNames);
      return subSpecialistNames;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const getfaqfirst = async () => {
    let updatedurl = currentURL.replace(/-/g, " ");
    let parts = currentURL.split("/");
    if (parts[1] == "ahsgpt") {
      setPageName(parts[1]);
    } else {
      setPageName(parts[2]);
    }
    let pagenameFirst = parts[2].replace("-", " ");
    try {
      const body = {
        question: pagenameFirst,
        loggedInUserRoleId: loggedInUserRoleId,
      };
      const res = await axios.post(`${OPENAI_API}faq_search`, body);
      if (res.data && res.data.answer) {
        setDocumentURLRes(res.data.answer);
      } else {
        console.error("API response is missing 'answer'.", res);
      }
    } catch (err) {
      console.error("Error:", err);
      return [];
    }
  };

  useEffect(() => {
    getfaqfirst();
  }, []);

  const coe_listapi = async (speciality, subSpecialty) => {
    let apiurl = sls05baseurl + ApiHelper.getCoeBySubSpeciality;
    try {
      const body = {
        speciality: speciality, 
        subSpecialty: subSpecialty
      };
      const res = await axios.post(`${apiurl}`, body);
      const { data } = res;
      const coeList = res.data.commandResult.data.practices.map((item) => ({
        id: item.id,
        company: item.company, 
      }));
      setcoeList(coeList);
      return coeList;
    } catch (error) {
      console.error("Error:", error);
      throw error; 
    }
};

  const physician_listapi = async (
    speciality,
    subSpeciality,
    companySlug
  ) => {
    let apiurl = sls05baseurl + ApiHelper.getCoePhysicians;
    try {
      const body = {
        speciality: speciality,
        subSpeciality: subSpeciality,
        companySlug: companySlug,
      };
      const res = await axios.post(
        // "https://sls-eus-dev-elemrex-esign-05.azurewebsites.net/api/getCoePhysicians",
        `${apiurl}`,
        body
      );
      const { data } = res;
      const physicianList = res.data.commandResult.data.physicians.map(
        (item) => ({
          registered_physician_id: item.registered_physician_id,
          full_name: item.full_name,
        })
      );
      setPhysicianList(physicianList);
      // const physicianList = JSON.stringify(data);
      return physicianList;
    } catch (error) {
      console.error("Error:", error);
      throw error;
    }
  };

  const extractsecondOpinionjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    console.log("jsonString....", jsonString);
    SecondOpinionjsonObject = JSON.parse(jsonString);
    console.log("SecondOpinionjsonObject....", SecondOpinionjsonObject);
  };

  const extractuploadVideojson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    UploadVideojsonObject = JSON.parse(jsonString);
  };
  const extractpatientidjson = async (text) => {
    const userIdRegex = /"userId": "(\d+)"/;
    const match = text.match(userIdRegex);
    const userId = match ? match[1] : null;
    patient_id = userId;
    return userId;
  };

  const extractdenialjson = async (text) => {
    console.log("reason before function call:", rules);
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    const json = JSON.parse(jsonString);
    const reason = json.reason;
    console.log("reason", reason);
    const updatedRules = [...rules];
    // Append the new rule to the cloned array

    updatedRules.push(reason);
    console.log("updatedRules", updatedRules);
    // Update the state with the new array including the appended rule
    setRules(updatedRules);

    // const denial_reasons_obj = JSON.parse(denial_reasons);
    // denial_reasons_obj.denial_reasons.push(reason);
    //add reason at start
    // denial_reasons_obj.denial_reasons.unshift(new_reason);
    // Convert the modified object back to a JSON string and update the original variable
    // let denial_reasons_temp = JSON.stringify(denial_reasons_obj);
    // setDenial_reasons(denial_reasons_temp);
    // console.log("Updated denial reasons JSON:", denial_reasons);
    return reason
  };

  const extractbill = async (text) => {
    console.log("extracting bill json from ", text);
    const jsonStartIndex = text.lastIndexOf("```json")+7;
    const jsonEndIndex = text.indexOf("```", jsonStartIndex);
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    //convert jsonString to json object
    bill_json = JSON.parse(jsonString);
    // bill_json = jsonString;
    console.log("Bill : ", bill_json);
    console.log("Bill : ", typeof(bill_json));
    const textbefore_1 = text.indexOf("Here is the corrected");
    const textBeforeJson = text.substring(0, textbefore_1);
    const textAfterJson = text.substring(jsonEndIndex + 3);
    const buttonIndex = textAfterJson.lastIndexOf("Buttons:");
    const finalText = textBeforeJson + textAfterJson;
    console.log("text Before bill ", textBeforeJson);
    console.log("text After bill ", textAfterJson);

    return finalText;
  };

  const upload_authorization_file = async() => {
    try{
      const file_payload = new FormData();
      // console.log("file name: ", fileNames);
      file_payload.append("file", file);
      let res = await axios.post(
        `${OPENAI_API}validate_file`,
        // `http://127.0.0.1:5000/validate_file`,
        file_payload
      );
      console.log("response: ", res.data.message);
      return res.data.message;
    } catch(err){
      console.error("Error:", err);
    }
  };

  const get_rules_url = async() => {
    const reasonsString = rules
    console.log("reason str: ",reasonsString);
    try{
      let rules_payload = {
        rules: reasonsString
      }
      let rule_res = await axios.post(
        `${OPENAI_API}update_rules`,
        rules_payload
      );
      let rules_url = rule_res.data.rules_url;
      console.log("response rules: ", rules_url);
      return rules_url;
    } catch(err){
      console.error("Error:", err);
    }

  };
  const get_bill_url = async () => {
    try{
      let pdf_payload = {
        template_name: "insurance_template.html",
        data: bill_json
      }
      let pdf_url = await axios.post(
        // `http://127.0.0.1:5000/generate_pdf`, 
        `${OPENAI_API}generate_html_to_pdf`,
        pdf_payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        );
      console.log("pdf_url: ",pdf_url);
      return pdf_url;
    } catch(err){
      console.error("Error:", err);
    }
  }
  const get_bill_patient = async () => {
    try{
      let pdf_payload = {
        template_name: "patient_template.html",
        data: bill_json
      }
      let pdf_url = await axios.post(
        // `http://127.0.0.1:5000/generate_patient_pdf`, 
        `${OPENAI_API}generate_html_to_pdf`,
        pdf_payload,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
        );
      console.log("pdf_url: ",pdf_url);
      return pdf_url;
    } catch(err){
      console.error("Error:", err);
    }
  }

  const handleUploadVideoAPI = async (hipaa_value) => {
    try {
      azureUploadResponse = await uploadToAzure();
      uploadVideoFileName = azureUploadResponse.data.commandResult.data.fileName;
      if (hipaa_value == 10) {
        uploadVideoFileUrl = azureUploadResponse.data.commandResult.data.fileUrl;
      }
      if (hipaa_value == 9) {
        uploadFileUrl = azureUploadResponse.data.commandResult.data.fileUrl;
      }
      console.log("File uri: ", uploadVideoFileUrl,uploadFileUrl );
      const uploadVideoRes = await uploadVideoToPatient(hipaa_value);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const uploadToAzure = async () => {
    let res;
    console.log("User id: ", patient_id, file);
    const azureBody = new FormData();
    azureBody.append("loggedInUserId", patient_id);
    azureBody.append("fileKey", file);
    try {
      const azureApiUrl = phpbaseurl + ApiHelper.php_uploadAzureFile;
      res = await axios.post(azureApiUrl, azureBody);
      console.log("Response: ", res);
    } catch (err) {
      console.error("Error:", err);
    }
    return res;
  };

  const generateVideoURI = async (que) => {
    console.log("called function");
    try {
      if (uploadVideoFileUrl !== undefined) {
        console.log("uploadVideoFileUrl", uploadVideoFileUrl);
        console.log("called new function");
        let videoBody = {
          video_data: uploadVideoFileUrl,
          video_question: que,
        };
        // const body = new FormData();
        // body.append("video_data", file);
        // body.append("video_question", que);
        let res = await axios.post(`${VERTEX_API}analyze_video`, videoBody);
        let response = res.data.answer;
        return response;
      } else {
        console.log("called table function");
        console.log("videoUrl.length", videoUrl.length);
        let r1;
        if (pdfUrl.length > 0) {
          r1 =
            "Anatomical abnormalities would be structural changes or irregularities in the body, which are usually identified through imaging studies like X-rays, MRI, CT scans, or physical examinations, not through lab results.";
        }
        if (imageUrl.length > 0) {
          if (imageUrl.length !== 1) {
            r1 =
              "Please select only one document check anatomical abnormalities!";
          } else {
            r1 = await report(que, imageUrl);
            SetisOtherQue(true);
          }
        }
        if (videoUrl.length > 0) {
          console.log("video url selected ");
          r1 = await report(que, videoUrl);
          console.log("r1 of video", r1);
          SetisOtherQue(true);
        }
        // let videoBody = {
        //   video_data: videoUrl[0],
        //   video_question: que
        // };

        // let response = await axios.post(
        //   `${VERTEX_API}analyze_video`,
        //   videoBody,
        //   {
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //   }
        // );
        // return response.data.answer
        return r1;
      }
    } catch (error) {
      console.error("Error:", error);
      return "Something went wrong! Please try again...";
    }
  };

  const uploadVideoToPatient = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_uploadEsignMultipleFiles;
    const body = {
      userId: patient_id,
      isDelegating: null,
      delegateId: null,
      parentId: null,
      isUploadFile: true,
      data: [
        {
          RecordId: 0,
          UploadedPracticesListSelected: [],
          UploadedPhysicanListSelected: [
            {
              doctor_id: "435",
              id: "1",
              physician_id: loggedInUserId,
              company: "Princeton Surgical Specialties COE",
              company_domain: "jamiewisser.medrecordexchange.com",
              company_url: "jamiewisser",
              company_phone: "17323332222",
              company_email: "jamie@jamie.com",
              first_name: "Jamie",
              last_name: "Wisser",
              address: "East Windsor",
              full_address: "43 Lincoln Pl Newton, New Jersey(NJ), 07860",
              physician_email: loggedInUserEmail,
              physician_mobile: loggedInUserMobile,
              physician_name: empName,
              physician: empName + "(Princeton Surgical Specialties COE)",
            },
          ],
          practiceInfo: "",
          isHippa: "10",
          file: uploadVideoFileUrl,
          fileName: uploadVideoFileName,
        },
      ],
      docType: 4,
    };
    try {
      const res = await axios.post(`${apiurl}`, body);
      console.log("Response of Esign: ", res);
      // patientData = res.data.commandResult.data.demographicUsers.map(
      //   (item) => ({
      //     UserId: item.from1UserId,
      //     Name: `${item.from1FirstName} ${item.from1LastName}`,
      //     Email: item.Email,
      //     Mobile: item.Mobile,
      //     Address: `${item.form1StreetName}, ${item.form1City}, ${item.form1State}, ${item.form1ZIP}`,
      //   })
      // );

      // setpatientDetails(patientData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleDropdownChange = async (event) => {
    demo = event.target.value;
    let nContext;
    if (specialistName === "") {
      specialistName = event.target.value;
      console.log("specialistName...", specialistName);
      let subspelist = await subSpecialist_listapi(specialistName);
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is subSpecialistNames  ${JSON.stringify(subspelist)}`,
        },
      ];
    } else if (sub_specialistName === "") {
      sub_specialistName = event.target.value;
      let coenamelist = await coe_listapi(specialistName, sub_specialistName);
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(coenamelist)}`,
        },
      ];
    } else if (practice_name === "") {
      practice_name = event.target.value;
      if (practice_name.includes("Princeton")) {
        practice_name = "jamiewisser";
      } else if (practice_name.includes("HITLAB Surgical")) {
        practice_name = "hitlab";
      }
      let physicianlistname = await physician_listapi(
        specialistName,
        sub_specialistName,
        practice_name
      );
      nContext = [
        ...context,
        { role: "user", content: event.target.value },
        {
          role: "system",
          content: `here is coe names  ${JSON.stringify(physicianlistname)}`,
        },
      ];
    } else if (physician_name === "") {
      physician_name = event.target.value;
      nContext = [...context, { role: "user", content: event.target.value }];
    } else {
      nContext = [...context, { role: "user", content: event.target.value }];
    }
    setContext(nContext);
    const userMessage = {
      text: event.target.value,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa((prevmessagesa) => [
      ...prevmessagesa,
      userMessage,
      aiResponse,
    ]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
    SetDropdownNames([]);
  };

  const getPatientDetails = async () => {
    let apiurl = sls05baseurl + ApiHelper.getUsersListByPhysicianId;
    const body = {
      company_slug: "jamiewisser",
      loggedInUserId: loggedInUserId,
    };

    try {
      const res = await axios.post(
        // "https://api.medrecordexchange.com/dev/getUsersListByPhysicianId.php",
        `${apiurl}`,
        body
      );
      patientData = res.data.commandResult.data.demographicUsers.map(
        (item) => ({
          UserId: item.DemographicId,
          Name: `${item.from1FirstName} ${item.from1LastName}`,
          Email: item.Email,
          Mobile: item.Mobile,
          Address: `${item.form1StreetName}, ${item.form1City}, ${item.form1State}, ${item.form1ZIP}`,
        })
      );

      setpatientDetails(patientData);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  window.test = (btnName) => {
    demo = btnName;
    if (demo === "Top 5 Documents") {
      SetbuttonNames([
        "Compare",
        "Summarize",
        "Anatomical Abnormalities",
        "Other Question",
        "Reset",
      ]);
      let aiResponse = { text: html_table, user: false };
      let userMessage = { text: demo, user: true };
      setmessagesa([...messagesa, userMessage, aiResponse]);

      // let nContext = [
      //   ...context,
      //   { role: "user", content: "Top 5 Documents" },
      // ];
      // setContext(nContext);
      // const userMessage = {
      //   text: "Show Top 5 Documents",
      //   user: true,
      // };
      // let aiResponse = {
      //   text: "please wait...",
      //   user: false,
      // };
      // setmessagesa([...messagesa, userMessage, aiResponse]);
      // // //speak({ text: aiResponse.text,voice: voices[4] });
      // getCompletionFrommessagesa(nContext);
    } else {
      let nContext = [...context, { role: "user", content: "Start dictation" }];
      setContext(nContext);
      const userMessage = {
        text: "Start Dictation",
        user: true,
      };
      let aiResponse = {
        text: "please wait...",
        user: false,
      };
      setmessagesa([...messagesa, userMessage, aiResponse]);
      // //speak({ text: aiResponse.text, voice: voices[4] });
      getCompletionFrommessagesa(nContext);
    }
  };
  const report = async (user_question, url) => {
    console.log("user_question...", url.toLowerCase().endsWith(".pdf"));
    let response;
    const urls = Array.isArray(url) ? url : [url];
    const isPdf = urls.every(
      (url) =>
        url.toLowerCase().endsWith(".pdf") ||
        url.toLowerCase().endsWith(".docx")
    );
    const isImage = urls.every(
      (url) =>
        url.toLowerCase().endsWith(".jpg") ||
        url.toLowerCase().endsWith(".jpeg") ||
        url.toLowerCase().endsWith(".dcm") ||
        url.toLowerCase().endsWith(".dicom") ||
        url.toLowerCase().endsWith(".png")
    );
    const isVideo = urls.every(
      (url) =>
        url.toLowerCase().endsWith(".webm") ||
        url.toLowerCase().endsWith(".mp4")
    );
    console.log("user_question 2...", url.toLowerCase().endsWith(".pdf"));
    try {
      if (isPdf) {
        console.log("in pdf if");
        // Create a separate body object for the PDF API call
        const pdfBody = {
          question: user_question,
          pdf_blob_urls: [url],
        };

        response = await axios.post(`${OPENAI_API}reports`, pdfBody);
      } else if (isImage) {
        let urlone = url[0];
        // Create a separate body object for the image API call
        const imageBody = {
          img_data: urlone,
          question: user_question,
        };

        response = await axios.post(`${VERTEX_API}analyze_image`, imageBody);
      } else if (isVideo) {
        if (url.length !== 1) {
          return "Please select only one video url to summarize";
        } else {
          let urlone = url[0];
          const videoBody = {
            video_data: urlone,
            video_question: user_question,
          };
          response = await axios.post(
            `${VERTEX_API}analyze_video`,
            // `http://127.0.0.1:5000/generate_uri`,
            videoBody,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          console.log("Response: ", response.data.answer);
        }
      } else if (!isPdf && !isImage && !videoUrl) {
        let urlone = url[0];
        const imageBody = {
          img_data: urlone,
          question: user_question,
        };
        response = await axios.post(`${VERTEX_API}analyze_image`, imageBody);
      }
      if (response) {
        console.log(response.data.answer, "response.data.answer");
        return response.data.answer;
      } else {
        console.log("No valid API call was made.");
      }
    } catch (error) {
      console.error("Error calling Python API:", error.message);
    }
  };
  window.demoTest = (url) => {
    SweetAlertHelper.showConfirm(
      "Are you sure you want to open this documents in new tab?",
      () => {
        window.open(url, "_blank");
      }
    );
  };

  window.printDocumentUrl = (url) => {
    console.log(
      "condition: ",
      url.toLowerCase().endsWith(".pdf") || url.toLowerCase().endsWith(".docx")
    );
    if (
      url.toLowerCase().endsWith(".pdf") ||
      url.toLowerCase().endsWith(".docx")
    ) {
      SetPdfUrl([...pdfUrl, url]);
      console.log("url of pdf", pdfUrl);
    } else if (
      url.toLowerCase().endsWith(".jpg") ||
      url.toLowerCase().endsWith(".jpeg") ||
      url.toLowerCase().endsWith(".png") ||
      url.toLowerCase().endsWith(".")
    ) {
      console.log("url of image", url);
      SetImageUrl([...imageUrl, url]);
    } else if (
      url.toLowerCase().endsWith(".webm") ||
      url.toLowerCase().endsWith(".mp4")
    ) {
      setVideoUrl([...videoUrl, url]);
      console.log("url of video: ", videoUrl);
      console.log("url of video 1: ", url);
    } else {
      console.error("Invalid PDF URL:", url);
    }
  };

  window.testRespond = (RecordId) => {
    demo = "Send Response";
    let nContext = [
      ...context,
      {
        role: "system",
        content: `Physician selected this RecordID ${RecordId}`,
      },
      { role: "user", content: "Send Response" },
    ];
    setContext(nContext);
    const userMessage = {
      text: "Send Response",
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  };

  window.testUpdatedRespond = (btntxt) => {
    demo = btntxt;
    // setResponse("btntxt");
    let nContext = [...context, { role: "user", content: btntxt }];
    setContext(nContext);
    const userMessage = {
      text: btntxt,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  };

  window.testAutoRespond = (btntext) => {
    demo = btntext;
    // setResponse(btntext);
    let nContext = [...context, { role: "user", content: btntext }];
    setContext(nContext);
    const userMessage = {
      text: btntext,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  };

  const extractDocumentjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    Documentjson = JSON.parse(jsonString);
    var doc = await getPatientDocuments(Documentjson);
    let dContext = [
      ...context,
      {
        role: "system",
        content: `user has provided html code in variable HTMLCODE: '''${doc}''', you need to return code without any change as output(note: Do not include  HTMLCODE: and quotes). for ex: HTMLCODE: '''<html><b> Hello</b>''' then return output as <html><b> Hello</b>`,
      },
    ];
    setContext(dContext);
    return Documentjson;
  };

  const getPatientDocuments = async (Documentjson) => {
    let apiurl = sls05baseurl + ApiHelper.getTopDocumentsList;
    try {
      const res = await axios.post(`${apiurl}`, Documentjson);
      patientDoc = res.data.commandResult.data.documentList.map((item) => ({
        DocumentName: item.DocumentName,
        DocumentUrl: item.DocumentUrl,
        DocumentDescription: item.DocumentDescription,
      }));
      const obj = {
        docs: patientDoc,
      };
      const table_response = await axios.post(
        // `http://127.0.0.1:5000/get_doc_table`,
        `${OPENAI_API}get_doc_table`,
        obj
      );
      html_table = table_response.data.assres;
      checkboxtable = html_table;
      console.log("HTML Table api response: ", html_table);
      setHtmlFormat(html_table);
      return html_table;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getPatientExist = async (pName) => {
    let apiurl = phpbaseurl + ApiHelper.php_gptIsPatientExists;
    const body = {
      patientName: pName,
    };
    try {
      const res = await axios.post(`${apiurl}`, body);
      const patient = res.data.commandResult.message;
      return patient;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  window.generatePDF = (UserId) => {
    demo = "Generate PDF";
    let nContext = [
      ...context,
      {
        role: "system",
        content: `Physician selected this UserId ${UserId}`,
      },
      { role: "user", content: "Generate PDF" },
    ];
    setContext(nContext);
    const userMessage = {
      text: "Generate PDF",
      user: true,
    };
    let aiResponse = {
      text: "Please wait, generating PDF...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    // //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
  };

  const pendingReferalOpinionAPI = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_findOpinionLogApi;
    const body = {
      userId: loggedInUserId,
      logType: "1",
    };
    try {
      const res = await axios.post(
        // "https://api.medrecordexchange.com/dev/findOpinionLog.php",
        `${apiurl}`,
        body
      );
      pendingReferalOpinionList = res.data.commandResult.data.logs
        .filter(
          (item) => item.HasResponse === 0 && item.ResponseDateTime === ""
        )
        .map((item) => ({
          RecordId: item.RecordId,
          PatientName: item.PatientName,
          RequestDateTime: item.RequestDateTime,
          RequestingDoctorName: item.RequesitngDoctorName,
          RequestingDoctorEMail: item.RequesitngDoctorEmail,
          ResponseRequest: item.ResponseRequest,
        }));
      return pendingReferalOpinionList;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getPatientDetails();
    pendingReferalOpinionAPI();
  }, []);

  const extractpendingsecondOpinionjson = async (text) => {
    const jsonStartIndex = text.lastIndexOf("{");
    const jsonEndIndex = text.indexOf("}", jsonStartIndex) + 1;
    const jsonString = text.substring(jsonStartIndex, jsonEndIndex);
    PendingSecondOpinionjsonObject = JSON.parse(jsonString);
  };

  const sendResponseToSecondOpinionAPI = async () => {
    let apiurl = phpbaseurl + ApiHelper.php_sendD2dResponse;
    try {
      const body = {
        logId: PendingSecondOpinionjsonObject.logId,
        emailText: PendingSecondOpinionjsonObject.emailText,
        behaviourId: PendingSecondOpinionjsonObject.behaviourId,
      };
      let result = await axios.post(
        // "https://api.medrecordexchange.com/dev/sendD2dResponse.php",
        `${apiurl}`,
        body
      );
    } catch (error) {
      console.error("Error:", error);
    }
  };

  let claimDocument =
    "https://blobtimesheet.blob.core.windows.net/pdfs/Cigna Health.pdf";

  // console.log("patient data...",patientData.UserId);

  const handleReportSelectAPI = async () => {
    console.log("calling report api", patientData);
    const body = new FormData();

    body.append("loggedInUserId", patientData.UserId);
    body.append("fileKey", file);
    let apiurl = phpbaseurl + ApiHelper.php_uploadAzureFile;
    try {
      const res = await axios.post(
        // "https://api.medrecordexchange.com/dev/uploadAzureFile.php",
        `${apiurl}`,
        body
      );
      console.log("res in file name and url", res);
      let filenameup = res.data.commandResult.data.fileName;
      let fileurl = res.data.commandResult.data.fileUrl;
      const obj = {
        pdf_blob_urls: [fileurl],
      };
      r1 = await axios.post(`${OPENAI_API}pdf_to_json`, obj);
      console.log("r1...", r1);
      const answerArray = r1.data.answer;
      const rjsonString = JSON.stringify(answerArray);
      console.log("rjsonString...", rjsonString);

      //  r1 = await report("Provide match codes from cigna health campared with services provided pdf along with there price", [claimDocument,fileurl]);
      // let nContext = [
      //   ...context,
      //   { role: "system", content: "Services provided by physician and their units as follows: \n"+ rjsonString },
      // ];
      // setContext(nContext);
      return rjsonString;
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const pendingTaskContext = `Your motive is to display the count of physician's pending task.
    When physician clicks on View pending tasks, display list of pending tasks with count on new line.
    if the any pending task count is null or 0 then, skip it or if all pending task count is null or 0 then Say you don't have any pending task for now. else
    say Sure, your pending tasks count :
    ${baseURL}/doctor/transfer-log/${loggedInUserId}/4 use html anchor tag and <br> tag for example: <a href=" ${baseURL}/doctor/transfer-log/${loggedInUserId}/4" target= "_blank">Doctor to Doctor Email: </a> ${doctorToDoctorEmailPendingCount} .\
    ${baseURL}/doctor/transfer-log/${loggedInUserId}/3 use html anchor tag for example: <a href=" ${baseURL}/doctor/transfer-log/${loggedInUserId}/3" target= "_blank">Patient opinion request: </a> ${patientSecondOpinionPendingCount}
    ${baseURL}/doctor/transfer-log/${loggedInUserId}/2 use html anchor tag for example: <a href=" ${baseURL}/doctor/transfer-log/${loggedInUserId}/2" target= "_blank">Physician initiated 2nd opinion: </a> ${physicianSecondOpinionPendingCount}
    ${baseURL}/doctor/transfer-log/${loggedInUserId}/1 use html anchor tag for example: <a href=" ${baseURL}doctor/transfer-log/${loggedInUserId}/1" target= "_blank">2nd opinion log expert: </a> ${expertSecondOpinionPendingCount} .\
    and say for detailed information follow the link.
   `;

  const FAQ_Document = ` You are the help document chatbot, here to assist with help document questions.
    When the user clicks on FAQ button or asks any questions related to faq then :
    for current ${currentLinkGpt} response steps are given here: ${documentURLRes}, Do not show deatiled steps only show last step which is like "For more details, go to <a href="https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/help_docs/AHS_Physician_Site_Instructions.pdf#page=${pageNumber}" target="_blank">${currentLinkGpt}</a> show anchor tag with the given url and ${pageNumber} (note: change page number in url to this ${pageNumber}" .
    first say to user that "You are on ${currentLinkGpt} page for more details click on this link (note : use html anchor tag only from above steps and If ${currentLinkGpt} is "ahsgpt" or "Dashboard" do not provide a html anchor tag and instead just say, "You are on ${currentLinkGpt} page. Do you have any questions on FAQ?" )" .\
    `;

  const SecondOpinionCOntext = `
     You are the second opinion chatbot, here to assist with second opinion related questions.
      When the user clicks on second opinion button then:
      You can ask following :
      create Dropdown array with speciality names from Specialist provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Multiple Sub-Specialty', 'sub-speciality2']
        - Say user to "Please select specialist name"
          Specialist Names: ${JSON.stringify(SpecialistList)}
        - Say user to "Please Select sub-specialist"
        - Say user to "Please Select Center Of Excellence(COE) name" (note : consider subSpecialty and subSpeciality are same words)(note: If Center Of Excellence(COE) name is not available then provide "Princeton Surgical Specialities" option in dropdown)
        - Say user to "Please Select Physician name" (note : consider subSpecialty and subSpeciality are same words)\
      - ask to user "Do you want to ask second opinion with PHI or without PHI". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['With PHI', 'Without PHI'] .\
      - if user says or clicks on "Without PHI" then follow step 2, 3, 5 and skip step 4 or if user says or clicks on "With PHI" then follow all steps like step 1, 3, 4, 5 and skip step 2 given below.
      step 1: You can ask following : (note : if user has provided the patient name which is in the active patients list then don't show dropdown directly follow step 4, take the respective patient id and add patient id in "demographicUserId" variable json as required)
        - create Dropdown array with active patient names from list of active patients provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Amit Sinha', 'Amit Sinha2']
          - first say user to "please Select patient name:" then show dropdown
            Active Patient Names: ${JSON.stringify(patientDetails)} 
          - first display the json and  take the UserId selected patient name and store in the "userId" variable "withphi-data" variable in JSON format as given below, and say "here is your json:" .\
            "withphi-data":
            {
              "userId": "542"
            }  
              and then say "Are you sure you want to ask second opinion for patient_name?" and provide Buttons: ['Yes', 'No'] 
            - When user says "Yes" then follow next step which is step-3 
      step 2: Ask user "Do you want to upload document" note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Yes upload', 'No'] then follow the sub-steps:
              1: If user selects "Yes upload" then ask user to upload document, like "Choose file to upload" store uploaded document name in ${filename1} and set "filename1" in json as filename - ${filename1} or ${file} note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Continue', 'Cancel']
                  if user say "Continue" store filename in ${filename1} and set "filename1" in json as filename ${filename1} or ${file}
              2: ask user "You have uploaded file_name. Do you want to upload another document" and store uploaded document name in "filename2". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Yes upload', 'No']
                  if user clicks "Yes upload" then ask user to upload document, like "Choose file to upload" store uploaded document name in ${filename2} and set "filename2" in json as filename - ${filename2} or ${selectedFile}. note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Continue', 'Cancel']
              3: ask user "You have uploaded file_name. Do you want to upload another document" and store uploaded document name in "filename3". note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Yes upload', 'No']
                  if user clicks "Yes upload" then ask user to upload document, like "Choose file to upload" store uploaded document name in ${filename3} and set "filename3" in json as filename - ${filename3} or ${selectedFile}.
      step 3: ask user to enter message and if user selects "Without PHI" then store user message in "emailLogTxt" variable or if user selects "With PHI" then store user message in "fileDesTxt" variable in json .
      step 4: ask user to "Choose document which you want to share", create Dropdown array with document names from documents list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['Doc1.pdf', 'Doc2.pdf'] (note : take respective RecordId of selected document from the list and store in "selectedDocsId" variable)        
        a) ask user "Do you want to share another document?" (note: user can upload maximum 5 documents so more that 5 times do not ask and take RecordId of selected selectedDocsId)note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['Yes', 'No'] .\
        b) If user says "Yes" then goto step 5 above.
        c) If user says "No" then goto below step.
      step 5: ask user to enter request time create Dropdown array with request time names (note: Dropdown D should be capital), like this, Dropdown: ['Urgent', '24 hours', '48 hours'] and store user selected request time option in "reuestTime" variable as 1 for  Urgent, 2 for  '24 hours', and 3 for '48 hours' .\
      instead of taking selected physician name take the registered_physician_id prvoide repective registered_physician_id for physicianId and consider loggedInUserId as ${loggedInUserId} and if companySlug contains "Princeton Surgical Specialties" then take companySlug as "jamiewisser" otherwise take companySlug as "hitlab").\
      if user selects "Without PHI" then consider "userOptionId" as "2" or if user selects "With PHI" then consider "userOptionId" as "1" in json and remember every user input and add to respective json key given in format below (note : provide data in proper json format only).
      Json format should look like this and if user selects "Without PHI" in "Second Opinion" then store in variable called "secondOpinion-data" and skip "selectedDocsId", "hippaFileDesTxt", "showNewFile", "attachmentDocObj" and "demographicUserId" variables from "secondOpinion-data" json or if user selects "With PHI" in "Second Opinion" then store in variable called "secondOpinion-data-PHI" and skip "filename1", "filename2" and "filename3" variables from "secondOpinion-data-PHI" json and consider RecordId of selected respective selectedDocsId(ex: "selectedDocsId":"7448") in "secondOpinion-data-PHI" json and say "Here is your Json:"(note: provide json variable name also with json data).
        "secondOpinion-data" or "secondOpinion-data-PHI" :
        {
            "filename1" : "${filename1}"
            "filename2" : "${filename2}",
            "filename3" : "${filename3}",
            "loggedInUserId": "loggedInUserId",
            "specialty": "speciality name",
            "subSpecialty": "subSpecialty name",
            "companySlug": "hospital name",
            "selectedPhysicianId":"physician id",
            "emailLogTxt" or "fileDesTxt" : "abcd",
            "physicianType": "1",
            "requestingPhyMobile": "9874563333",
            "requestingPhyEmail": "princeton_physician01@yopmail.com",
            "reuestTime": "1",
            "userOptionId": "1" or "2",
            "selectedDocsId": "8342","8341",
            "hippaFileDesTxt": "null",
            "showNewFile": "0",
            "attachmentDocObj":"null",
            "demographicUserId": "patient id",
            "docType": "11",
            "assignedDelegateId": "undefined",
            "isDelegated": "0",
            "selectedDelegateId": "loggedInUserId"
        }
        and show user entered information only (do not show id) in list for example: specialty - Cardiology, subspeciality: None etc below json and ask please check all details and confirm the provided details are correct? note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['I confirm', 'Cancel']
        if user say "I confirm" then say based on user selected option like for "Without PHI" as "Second opinion request for without PHI sent successfully, Do you need any other help?", and for "With PHI" as "Second opinion request for without PHI sent successfully, Do you need any other help?". if cancel then ask "Do you need any other help?".`;

  const newChat = `
  You are a friendly physician Assistance ChatBot, first greet the user with their last name Dr.${loggedInUserName}. \
   `;
  const pendingReferalOpinion = `You are friendly physician respond expert 2nd opinion Assistance ChatBot.
   When physician says respond expert 2nd opinion then :
   First check if there are pending opinions in list given in pendingReferalOpinionList:  ${JSON.stringify(
     pendingReferalOpinionList
   )} if no record are there then say "There are no pending opinions to respond. You have completed all pending opinion reviews".
   If records are there in the list then provide latest 5 pending opinions to respond from list given above in pendingReferalOpinionList in tablular format.
   If records are available then the table should display details with columns Patient Name(note: if Patient name is not available then display "Without PHI"), Requesting Doctor Name, Requesting Doctor Email (note: provide an HTML anchor tag for emails, example: <a href="test@yopmail.com" target="_blank">test@yopmail.com</a>), Request Date& Time, Response Request, Send Response (note: Provide Button "Send Response" in patient with respective RecordId as a parameter to testRespond function in row ex: <button class="send-button" onclick={testRespond(RecordId)}>Send Response</button>). Provide proper HTML table tag only (note: do not add '''html tag)with all borders for columns and rows code  do not add <html>, <title> or '''html etc tags(note : After physician clicks on "Send Response" then only ask for next step) .\
   - When physician clicks on "Send Response" then only ask physician "Would you like to select a pre-programmed opinion resonse?". Provide Buttons array (note: Buttons B should be capital)  Buttons: ['Yes', 'No'].\
   - If physician says "Yes" then say "Select your response" and provide two html buttons with button text 1. "I have had a chance to review your submitted files.  I believe I can be of assistance.  If you would like, please call my office ${formattedPhoneNumber} and we can set up an expedited appointment for me to evaluate you in person in the next 1-2 weeks. My staff will request your health insurance information.  I hope to see you soon!   Dr. ${updatedLoggedInUserName}",  2- "I have had a chance to review your submitted files.  I thank you for sharing them with me.  Based on my practice experience, it is my opinion that I am unfortunately unable to assist you with your presented medical issue at this time.  My recommendation is that you seek an alternative medical opinion." for example: <button onclick="testAutoRespond("I have had a chance to review your submitted files.  I thank you for sharing them with me.  Based on my practice experience, it is my opinion that I am unfortunately unable to assist you with your presented medical issue at this time.  My recommendation is that you seek an alternative medical opinion.")">I have had a chance to review your submitted files.  I thank you for sharing them with me.  Based on my practice experience, it is my opinion that I am unfortunately unable to assist you with your presented medical issue at this time.  My recommendation is that you seek an alternative medical opinion.</button>.(note: button text is complete response 1 or 2 and store button text or response in "emailText" variable in json format given below. )
       - when physician selects the reponse then say "Do you want to edit this response?" . Provide Buttons array (note: Buttons B should be capital)  Buttons: ['Yes', 'No'].\
       - If physician selects "yes" then show selected complete button text in textarea ask physician to edit(note : provide proper html textarea with id="response" in which physician can edit the response, ex: <textarea id="response" name="response" style="width: 800px; height: 100px;">I have had a chance to review your submitted files. I thank you for sharing them with me. Based on my practice experience, it is my opinion that I am unfortunately unable to assist you with your presented medical issue at this time. My recommendation is that you seek an alternative medical opinion.</textarea>
             <button onclick="testUpdatedRespond(document.getElementById('response').value)">Save response</button>). and provide one html button with button text "Save response". for example: <button onclick="testUpdatedRespond("I have had a chance to review your submitted files.  I thank you for sharing them with me.  Based on my practice experience, it is my opinion that I am unfortunately unable to assist you with your presented medical issue at this time.  My recommendation is that you seek an alternative medical opinion.")">Save response</button> .\
       - When physician clicks on "Save changes" button then take complete text from input box and pass that text as a user response and store that response in "emailText" variable in json format given below.\
       - If physician selects "no" then take as it is user selected response and store that response in "emailText" variable in json format given below.\
   - If physician says "No" then ask user "Please type your response" and store physician response in "emailText" variable (note: Physician asks please call, please contact on my cell number cell number then consider as physician response and store that to emailText, not ai things to do.).
   - Consider "behaviourId" as "3" and "logId" as physician selected RecordId and say Here is you Json:.
     "pendingReferalOpinion-data":
       {
         "logId":"RecordId",
         "emailText":"physician response",
         "behaviourId":"3"
       }
       and if physician selects preprogrammed opinion response then show emailText in html bold tag below json and on newline ask  "Please review and confirm your response." or if physician types response then show emailText in html bold tag below json and on newline and ask  "Here is your typed response. Please confirm.".  note: also provide Buttons array(note: Buttons B should be capital)  like this, Buttons: ['I confirm', 'Cancel']
       if physician say "I confirm" then say "Your opinion has been emailed to the requesting doctor.". If "Cancel" then ask "Do you need any other help?" and ask to physician "Would you like to review the next pending opinion request on your list?". Provide Buttons array (note: Buttons B should be capital)  Buttons: ['Yes', 'No'] .\
       If physician say "yes" then provide all remaining pending opinions excluding above selected RecordId(note: if user Cancelled that RecordId then do not exclude) from list given above in pendingReferalOpinionList in tablular format (note : if no records are available in pendingReferalOpinionList then say "You have completed all pending opinion reviews. Do you need any other help?) .\
       If physician say "no" then ask "Do need any other help?" .\
   `;

  const Patient_details = `
  You are the patient_details chatbot, here to assist with patient_details related questions.
  When the user clicks on the patient_details button:
    - Ask to user "Sure, could you please provide me with the name of the patient?"
    - Search the user-entered name in the patient list: ${JSON.stringify(
      patientDetails
    )}
    -If there are multiple matching names in the list, generate a dropdown array (capitalizing 'Dropdown') containing the names and corresponding IDs of the patients from the provided list. For cases where there is only one patient with the name, simply state "Here are the details of [Patient_name]". For example, if there are two patients named John, the dropdown array would look like this: Dropdown: ['John don-575', 'John Merry-225'].
    - show the details of selected paitent from patientDetails list, then display details in tabular format with columns Name, Email (note: provide an HTML anchor tag for emails, example: <a href="test@yopmail.com" target="_blank">test@yopmail.com</a>), Mobile, Address, Documents (note: Provide Button "Show Documents" in patient row ex: <button class="send-button" onclick={test('Top 5 Documents')}>Top 5 Documents</button>), Dictation (note: Provide Button "Start Dictation" in patient row ex: <button class="send-button" onclick={test('Start Dictation')}>Start Dictation</button>). Provide proper HTML table tag only(do not add '''html) with all borders for columns and rows code with proper CSS, also take the UserId selected patient name and
      store in the "userId" variable and consider the limit as 5 and store these two values in "userDoc-data" variable in JSON format as given below .\
      "userDoc-data":
      {
        "userId": "542",
        "limit": "5"
      }
     -If user says "start dictation" then ask to user "Are you sure you want to start dictation for patient_name?", note: also provide Buttons array(note: Buttons B should be capital)  like this,  Buttons: ['Yes, Start Dictation', 'Cancel Dictation']
     - if user clicks on "cancel" then say "Do you need any other Help?"
    - if user clicks on "Yes, Start Dictation" just say "dictation started for patient_name" provide One Buttons: ['Stop Dictation']    
    - if user clicks on "Stop Dictation" then say "Dictation stopped for patient_name. All files(dictation.mp3, dictation-transcription.txt and dictation-summary.txt) are downloaded as well as saved in patient_name comments. Do you want to summary of this conversation?" provide two Buttons: ['Show Summary', 'No'].
    - if user clicks on "show summary" then display summary report provide above in "Report Summary:" as it is with "Abstract Paragraph" and "summary" points in Bold, (Do not change any report content display as it is.)
    - if user clicks on "No" then just say "Do you need any other help?"
    - If user says "Show Top 5 Documents" then say "Here is the list of the patient's 5 most recent documents" in tabular format with column names DocName, DocURL (note: provide HTML anchor tag for URLs, for demoTest function also provide the same DocURL example: <a href="javascript:void(0);"  onclick={demoTest("https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/lorem-ipsum_08_167925049.pdf")}>lorem-ipsum_08</a>), DocDescription and Select PDF as checkbox at each row call javascript function to pass document url for example: <input type="checkbox" id="doc1" name="doc1" value="doc1" onclick="printDocumentUrl('https://elemrexproductionstorage.blob.core.windows.net/elemrex-esign-01/lorem-ipsum_08_1679250494.pdf')">.
      Provide proper HTML table tag with all borders for columns and rows code with proper CSS (note: strictly show documents in tabular format only). note: also provide Buttons array(note: Buttons B should be capital)  like this,  Buttons: ['Compare', 'Summarize', 'Anatomical Abnormalities', 'Other Question', 'Reset'] .\
      when physician say "compare" then display comparison of document in proper html and css format.
    - If the patient name is not available in the list, then say, "Sorry, this patient is not registered under you."
    - If the user asked for the active patient list or my patient list, then display the first 5 patient names list in tabular format with columns Name, Email (note: provide an HTML anchor tag for emails, example: <a href="test@yopmail.com" target="_blank">test@yopmail.com</a>), Mobile, Address. Provide proper HTML table tag with all borders for columns and rows code with proper CSS. and say, "For more details of active patients, click on this link:" Provide a link ${baseURL}/doctor/demographics/${loggedInUserId}/0 using an HTML anchor tag, for example: <a href="${baseURL}/doctor/demographics/${loggedInUserId}/0" target="_blank">Active Patient Details</a>`;

  const dictationContext = `
  You are the patient-physician dictation chatbot, here to assist with dictation related questions.
  When the user clicks on the start dictation button:
    - Ask to user "Sure, could you please provide me with the name of the patient?"     
    - Search the user-entered name in the patient list: ${JSON.stringify(patientDetails)}
  - If multiple matching names are there in the list, then create a Dropdown array if there is only one paitent with the given name then do not create dropdown just say "Here are the details of Paitent_name" else say "please select patient name:" with matching patient names-id from the list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['John don-575', 'John Merry-225'] .\   
  - show the details of selected paitent from patientDetails list, then display details in tabular format with columns Name, Email (note: provide an HTML anchor tag for emails, example: <a href="test@yopmail.com" target="_blank">test@yopmail.com</a>), Mobile, Address. Provide proper HTML table tag only(do not add '''html) with all borders for columns and rows code with proper CSS,
    ask user "Are you sure you want to start dictation for patient_name?", note: also provide Buttons array(note: Buttons B should be capital)  like this,  Buttons: ['Yes, Start Dictation', 'Cancel Dictation'] , also take the UserId selected patient name and
    store in the "userId" variable in below json(do not show json''') and say "here is you json":
    "dictation-data":
    {
      "userId": "542",       
    }
  - if user clicks on "cancel" then say "Do you need any other Help?"
  - if user clicks on "Yes, Start Dictation" just say "dictation started for patient_name" provide One Buttons: ['Stop Dictation']    
  - if user clicks on "Stop Dictation" then say "Dictation stopped for patient_name. All files(dictation.mp3, dictation-transcription.txt, dictation-summary.txt) are downloaded as well as saved in patient_name comments. Do you want to upload image or document regading this AutoScrib?" provide two Buttons: ['Yes, I want to upload', 'No'].
  - if user clicks on "Yes, I want to upload" then ask user "Do you want to upload existing image or you want capture new image?" provide two Buttons: ['Upload Existing Image', 'Capture New Image'].
  - when user says "No" then say "Do you want to summary or prescription of this conversation?" provide three Buttons: ['Show Summary', 'Show Prescription', 'No'].
  - if user clicks on "show summary" then display summary report provide above in "Report Summary:" as it is with "Abstract Paragraph" and "summary" points in Bold and say to use "Do you need any other help?", (Do not change any report content display as it is.)
  - if user clicks on "No" then just say "Do you need any other help?"
  - If the patient name is not available in the list, then say, "Sorry, this patient is not registered under you. Are you sure you want to start dictation for patient_name? Please keep note of dictation file will be stored locally only" note: also provide Buttons array(note: Buttons B should be capital)  like this,  Buttons: ['Yes, Start Dictation', 'Cancel Dictation']
  - If the user asked for the active patient list or my patient list, then create a Dropdown array(for there is only one paitent with the name the do not create dropdown just say "Here are the details of Paitent_name") with all patient names from the list provided above (note: Dropdown D should be capital), like this, ex: Dropdown: ['John don', 'John Merry'] `;

  const uploadVideoContext = `
    You are the upload file chatbot, here to assist with upload file.
    If user says or clicks on "Upload Video" then:   
    - Ask to user "Sure, could you please provide me with the name of the patient?"     
      - Search the user-entered name in the patient list: ${JSON.stringify(
        patientDetails
      )}
      - If there's only one record found either matching by firstname or lastname, there's no need to display the dropdown just proceed with next step. else if there are multiple matching names in the list, then say "Please select patient name:" and create a dropdown containing the matching patient names and their corresponding IDs from the provided list. (Note: Ensure the capitalization of 'Dropdown' as 'Dropdown') For example, if there are multiple patients named John, the dropdown would appear as follows: Dropdown: ['John don-575', 'John Merry-225']. 
    - ask user  "Are you sure you want to upload video for patient_name? Uploading only mp4 files is allowed; files in txt, pdf, and png formats are not permitted." also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Yes upload video', 'Cancel'] also take the UserId selected patient name and
      store in the "userId" variable in below json(do not show json''') and say "here is you json":
      "video-data":
      {
        "userId": "542",       
      }       
      - If user selects "Yes upload video" then ask user to upload video, like "Choose mp4 video to upload" store uploaded video name in ${filename1} and set "filename" in json as filename - ${filename1} or ${selectedFile} note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Cancel'] .
      - if user say "file_name" store filename in ${filename1} and ${selectedFile} and ask "file_name successfully uploaded." also, provide buttons array like, (note: Buttons B should be capital) Buttons: ['Detailed Summary', 'Anatomical Abnormalities', 'Ask Question']
    `;

    const patient_david = `
    {
      "patient": {
        “patient_name”: {
          "First name": "David",
          “Last name”: “Clair”,
        },
        "date_of_birth": "1997-05-25",
        "gender": "Male",
        "address": "132, My Street, Kingston, New York 12401",
        "phone": "555-153-4361",
        "email": "david_clair@yopmail.com",
        “status”: “married”, 
      },
      "insurance": {
        "primary_insurance": {
          "insured’s_name": {
        “First_name”: "Laura",
        “last_name”: “Carter”,
        “Middle_name”: “Nathan”
          },
          "insured’s_address": "264, My Street, Kingston, New York 12401",
          “insured_date_of_birth”: “1994-01-20”,
          "company_name": "Cigna",
          "policy_number": "HC987654",
          "group_id": "G54321",
          “insured_ID”: “187-837-3728”,
          "relationship_to_patient": "Spouse",
          "plan_name": "PPO",
          “plan_type”: “Group health plan”
        },
        "secondary_insurance": {
          "insured’s_name": {
        “First_name”: "Carol",
        “last_name”: “Smith”,
        “Middle_name”: “Finn”
          },
          “Insured’s_address”: “164, Greenwich St, Washington country, NY”,
          “insured’s_date_of_birth”: “1981-09-23”,
          "company_name": "Bluestone",
          "policy_number": "CP789012",
          "group_id": "H04121",
          “insured_ID”: “291-989-2829”,
          "plan_holder_name": "Carol Smith",
          "relationship_to_patient": "Parent",
          "plan_name": "ClassicHSA",
          “plan_type”: “group health plan”
        },
      "medicare_coverage": {
        "part_a": {
          "deductible": 1484,
          "coinsurance_days": 61,
          "lifetime_reserve_days": 60
        }
      }
    }`
    const ER_Ambulance = `
    {
      "Service provider name": "The heart centre",
      "Service provider address": "33 grand st. suite 53, kingston ",
      "Service provided": {
      "Date": "05-01-2023"  
      "services": {
        "1": "Emergency room",
        "2": "Ambulance",
        },
      "Unit": {
        "1": " hour",
        "2": " km",
      },
      "Quantity": {
        "1": "1",
        "2": "2",
        },
        "Charges": {
        "1": "$100 per unit",
        "2": "$200 per unit",
        }
      }
    }
    `
    const Syringe_Room = `“Service provided”: {
      “services”: {
    “1”: “Syringe with needle, Sterile 3cc”,
    “2”: “Hospital long term care, room and board”
    }
      “Unit”: {
        “1”: “cc”,
        “2”: “diem”
      }
      “Quantity”: {
        “1”: “3”,
        “2”: “5”
      },
      "Charges": {
        "1": "$150 per unit",
        "2": "$280 per unit",
      }
    }
    `
    const policy = `{
      "MedicalPlans": {
        "PPO": {
          "AnnualDeductible": {
            "EmployeeOnly": "$1,250",
            "EmployeePlusDependents": "$2,500"
          },
          "AnnualOutOfPocketMaximum": {
            "EmployeeOnly": "$5,000",
            "EmployeePlusDependents": "$10,000"
          },
          "AdultAndPediatricPreventiveCare": "No Charge",
          "AnnualOBGYNExam": "No Charge",
          "RoutineMammograms": "No Charge",
          "OfficeVisit": "$35 copay per visit",
          "SpecialistOfficeVisit": "$45 copay per visit",
          "MaternityCareOfficeVisits": "$35 copay per visit",
          "DiagnosticServicesXrayAndLabTests": "No Charge",
          "AdvanceRadiologyMRIsAndCTScans": "30% after deductible",
          "HospitalInpatientAndOutpatientSurgery": "30% after deductible",
          "EmergencyRoom": "No Charge after $50 copay",
          "Ambulance": "No Charge after $50 copay",
          "Prescriptions": {
            "Generic": "$10 copay",
            "NameBrand": "$45 copay",
            "BrandOutsideOfFormulary": "$60 copay",
            "MailOrderPrescriptions90DaySupply": {
              "Generic": "$20 copay",
              "NameBrand": "$90 copay",
              "BrandOutsideOfFormulary": "$80 copay"
            }
          },
        },
        "ClassicHSA": {
          "AnnualDeductible": {
            "EmployeeOnly": "$2,000",
            "EmployeePlusDependents": "$4,000"
          },
          "AnnualOutOfPocketMaximum": {
            "EmployeeOnly": "$4,500",
            "EmployeePlusDependents": "$9,000",
            "Notes": "Family OOP Max has embedded individual max of $6,850"
          },
          "AdultAndPediatricPreventiveCare": "No Charge",
          "AnnualOBGYNExam": "No Charge",
          "RoutineMammograms": "No Charge",
          "OfficeVisit": "20% after deductible",
          "SpecialistOfficeVisit": "20% after deductible",
          "MaternityCareOfficeVisits": "20% after deductible",
          "DiagnosticServicesXrayAndLabTests": "20% after deductible",
          "AdvanceRadiologyMRIsAndCTScans": "20% after deductible",
          "HospitalInpatientAndOutpatientSurgery": "20% after deductible",
          "EmergencyRoom": "20% after deductible",
          "Ambulance": "20% after deductible",
          "Prescriptions": {
            "Generic": "$20 copay after deductible",
            "NameBrand": "20% after deductible",
            "BrandOutsideOfFormulary": "20% after deductible",
            "MailOrderPrescriptions90DaySupply": {
              "Generic": "$20 copay after deductible",
              "NameBrand": "$20 copay after deductible",
              "BrandOutsideOfFormulary": "$20 copay after deductible"
            }
          },
        },
        "PremierHSA": {
          "AnnualDeductible": {
            "EmployeeOnly": "$2,000",
            "EmployeePlusDependents": "$4,000"
          },
          "AnnualOutOfPocketMaximum": {
            "EmployeeOnly": "$3,000",
            "EmployeePlusDependents": "$6,000",
            "Notes": "Family OOP Max has embedded individual max of $4,850"
          },
          "AdultAndPediatricPreventiveCare": "No Charge",
          "AnnualOBGYNExam": "No Charge",
          "RoutineMammograms": "No Charge",
          "OfficeVisit": "10% after deductible",
          "SpecialistOfficeVisit": "10% after deductible",
          "MaternityCareOfficeVisits": "10% after deductible",
          "DiagnosticServicesXrayAndLabTests": "10% after deductible",
          "AdvanceRadiologyMRIsAndCTScans": "$40 copay after deductible",
          "HospitalInpatientAndOutpatientSurgery": "10% after deductible",
          "EmergencyRoom": "10% after deductible",
          "Ambulance": "10% after deductible",
          "Prescriptions": {
            "Generic": "$10 copay after deductible",
            "NameBrand": "$25 copay after deductible",
            "BrandOutsideOfFormulary": "$40 copay after deductible",
            "MailOrderPrescriptions90DaySupply": {
              "Generic": "$20 copay",
              "NameBrand": "$50 copay",
              "BrandOutsideOfFormulary": "$80 copay"
            }
          }
        }
      }
    }
    `
    const bill_1 = `
    {
      "Carrier": "PICA",
      "Patient and Insured Information": {
        "Group Health Plan ID": "G54321",
      },
      "Patient Information": {
        "Name": "David Clair",
        "Street no": "132",
        "Birth Date": "1997-05-25",
        "Sex": "M",
        "Relationship to Insured": "Spouse",
        "City": "Kingston",
        "State": "NY",
        "Zip Code": "12401",
        "Telephone": "555-153-4361",
        "Marital Status": "Married"
      },
      "Insured Information": {
        "Insured's Name": "",
        "Date of Birth": "01-20-1994",
        "Sex": "F",
        "Employment": "YES",
        "Auto Accident": "NO",
        "Other Accident": "NO",
        "Insurance Plan Name": "PPO"
      },
      "Claim Information": {
        "Outside Lab Charges": "$0",
        "Service Dates": "05-01-2023",
        "Service provider name": "The heart centre",
        "Service provider address": "33 grand st. suite 53, kingston ",
        "Procedures, Services, or Supplies": [
          {
            "Date": "05-01-2023",
            "Service Type": "Emergency",
            "CPT/HCPCS": "99281",
            "Modifier": "25",
            "Charges": "$100",
            "Days or Units": "1 hr",
          },
          {
            "Date": "05-01-2023",
            "Service Type": "Ambulance",
            "CPT/HCPCS": "A0429",
            "Charges": "$400",
            "Days or Units": "2 km",
          },
          {
            "Date": "05-01-2023",
            "Service Type": "echocardiography",
            "CPT/HCPCS": "93306",
            "Charges": "$300",
            "Days or Units": "1",
          }
        ],
        "Federal Tax ID Number": "123123123",
        "Patient's Account No.": "1550",
        "Total Charge": "800",
        "Amount Paid": "650",
        "Balance": "150"
      }} 
    `
    const bill_2 = `
    {
      "insurance_type": "Group health plan",
      "insured_id": "187-837-3728",
      "patient_name": "David Clair",
      "patient_birth_date": "1997-05-25",
      "patient_sex": "M",
      "insured_name": "Laura Carter",
      "patient_address": "132, My Street",
      "patient_city": "Kingston",
      "patient_state": "NY",
      "patient_zip": "12401",
      "patient_telephone": "555-153-4361",
      "patient_insured_relationship": "Spouse",
      "insured_address": "264, My Street",
      "insured_city": "Kingston",
      "insured_state": "NY",
      "insured_zip": "12401",
      "insured_telephone": "555-153-4361",
      "marriage_status": "Married",
      "employment_status": "YES",
      "insurance_plan_name": "PPO",
      "employment": "YES",
      "auto_accident": "NO",
      "other_accident": "NO",
      "insured_policy_group_feca_number": "G54321",
      "insured_dob": "1994-01-20",
      "insured_sex": "F",
      "insured_insurance_plan_name": "PPO",
      "items": [
        {
          "date_of_service_from": "05-01-2023",
          "date_of_service_to": "05-01-2023",
          "type_of_service": "Emergency",
          "procedure_service_or_supplies": "Emergency",
          "cpt_hcpcs": "93281",
          "charges": "100",
          "days_or_units": "1",
        },
        {
          "date_of_service_from": "05-01-2023",
          "date_of_service_to": "05-01-2023",
          "type_of_service": "Ambulance",
          "procedure_service_or_supplies": "Ambulance service",
          "cpt_hcpcs": "A0424",
          "charges": "400",
          "days_or_units": "1",
        },
        {
          "date_of_service_from": "05-01-2023",
          "date_of_service_to": "05-01-2023",
          "type_of_service": "Diagnostic",
          "procedure_service_or_supplies": "Echocardiography",
          "cpt_hcpcs": "93206",
          "charges": "300",
          "days_or_units": "1",
        }
      ],
      "federal_tax_id": "123123123",
      "patient_account_no": "1550",
      "accept_assignment_yes_no": "YES",
      "total_charge": "800",
      "amount_paid": "650",
      "balance_due": "150",
      "service_facility_location": "The heart centre, 33 grand st. suite 53, kingston",
    }
    `

    const bill_3 = `
    {
      "Carrier": "PICA",
      "Patient and Insured Information": {
        "Group Health Plan ID": "G54321",
      },
      "Patient Information": {
        "Name": "David Clair",
        "Street no": "132",
        "Birth Date": "1997-05-25",
        "Sex": "M",
        "Relationship to Insured": "Spouse",
        "City": "Kingston",
        "State": "NY",
        "Zip Code": "12401",
        "Telephone": "555-153-4361",
        "Marital Status": "Married",
        "Drinking status": "often",
        "Smoking status": "sometimes"
      },
      "Insured Information": {
        "Insured's Name": "Laura Carter",
        "Date of Birth": "01-20-1994",
        "Sex": "F",
        "Employment": "YES",
        "Auto Accident": "NO",
        "Other Accident": "NO",
        "Insurance Plan Name": "PPO"
      },
      "Claim Information": {
        "Outside Lab Charges": "$0",
        "Service Dates": "05-01-2023",
        "Service provider name": "The heart centre",
        "Service provider address": "33 grand st. suite 53, kingston ",
        "Procedures, Services, or Supplies": [
          {
            "Date": "05-01-2023",
            "Service Type": "Emergency",
            "CPT/HCPCS": "99281",
            "Modifier": "25",
            "Charges": "$100",
            "Days or Units": "1",
          },
          {
            "Date": "05-01-2023",
            "Service Type": "Ambulance",
            "CPT/HCPCS": "A0429",
            "Charges": "$200",
            "Days or Units": "1",
          },
          {
            "Date": "05-01-2023",
            "Service Type": "echocardiography",
            "CPT/HCPCS": "93306",
            "Charges": "$300",
            "Days or Units": "1",
          }
        ],
        "Federal Tax ID Number": "123123123",
        "Patient's Account No.": "1550",
        "Total Charge": "600",
        "Amount Paid": "550",
        "Balance Due": "50"
      }}
    `
    const cuff_tear = `{
      "date":"05-01-2023",
      "radiograph": {
          "patient_name": "David Clair",
          "age": 55,
          "sex": "Male",
          "date_of_mri": "2023-03-08",
          "impression": [
              "Rotator cuff tear",
              "Glenohumeral joint dislocation",
              "Acromioclavicular joint dislocation"
          ],
          "findings": [
              "There is a complete tear of the supraspinatus tendon.",
              "There is a dislocation of the glenohumeral joint.",
              "There is a dislocation of the acromioclavicular joint."
          ],
          "labels": [
              "Rotator cuff tear",
              "Glenohumeral joint dislocation",
              "Acromioclavicular joint dislocation"
          ]
      }
    }`
    const Echocardiography = `{
      "specimenReceived": "Echocardiography of  heart (2D and Doppler)",
      "date":"05-01-2023",
      "interpretation": {
        "leftVentricle": {
          "dilation": "mild",
          "wallThickness": "normal"
        },
        "pericardialEffusion": {
          "amount": "small",
          "description": "Present"
        },
        "rightVentricle": {
          "size": "normal",
          "function": "normal"
        },
        "valvularFunction": {
          "regurgitation": "none",
          "stenosis": "none"
        },
        "aorta": {
          "size": "normal",
          "flow": "normal"
        },
        "pulmonaryArtery": {
          "size": "normal",
          "flow": "normal"
        },
        "inferiorVenaCava": {
          "dilation": "slightly",
          "flow": "normal"
        },
        "hepaticVeins": {
          "size": "normal",
          "flow": "normal"
        },
        "portalVein": {
          "size": "normal",
          "flow": "normal"
        },
        "spleen": {
          "size": "normal",
          "echogenicity": "normal"
        },
        "kidneys": {
          "size": "swollen",
          "echogenicity": "abnormal",
          "impression": "Probability of consumption of alcohol"
        },
        "gallbladder": {
          "size": "normal",
          "wallThickness": "normal",
          "stones": "none"
        },
        "pancreas": {
          "size": "normal",
          "echogenicity": "normal"
        },
        "retroperitonealLymphNodes": "not enlarged"
      },
      "diagnosis": "Mild left ventricular dilatation with pericardial effusion",
      "impression": "The findings are suggestive of a non-specific pericardial effusion"
    }`
    const blank_bill = `{
      "insurance_type": "",
      "insured_id": "",
      "patient_name": "",
      "patient_birth_date": "",
      "patient_sex": "",
      "insured_name": "",
      "patient_address": "",
      "patient_city": "",
      "patient_state": "",
      "patient_zip": "",
      "patient_telephone": "",
      "patient_insured_relationship": "",
      "insured_address": "",
      "insured_city": "",
      "insured_state": "",
      "insured_zip": "",
      "insured_telephone": "",
      "marriage_status": "",
      "employment_status": "",
      "other_insured_name": "",
      "other_insured_policy_number": "",
      "other_insured_dob": "",
      "other_insured_sex": "",
      "employer_school_name": "",
      "insurance_plan_name": "",
      "employment": "",
      "auto_accident": "",
      "other_accident": "",
      "local_use": "",
      "insured_policy_group_feca_number": "",
      "insured_dob": "",
      "insured_sex": "",
      "insured_employer_school_name": "",
      "insured_insurance_plan_name": "",
      "health_benefit_plan": "",
      "patient_authorized_signature": "",
      "signature_date": "",
      "insured_authorized_signature": "",
      "items": [
        {
          "date_of_service_from": "",
          "date_of_service_to": "",
          "place_of_service": "",
          "type_of_service": "",
          "procedure_service_or_supplies": "",
          "cpt_hcpcs": "",
          "diagnosis_code": "",
          "charges": "",
          "days_or_units": "",
          "epsdt_family_plan": "",
          "emg": "",
          "cob": "",
          "reserved_for_local_use": ""
        },
        {
          "date_of_service_from": "",
          "date_of_service_to": "",
          "place_of_service": "",
          "type_of_service": "",
          "procedure_service_or_supplies": "",
          "cpt_hcpcs": "",
          "diagnosis_code": "",
          "charges": "",
          "days_or_units": "",
          "epsdt_family_plan": "",
          "emg": "",
          "cob": "",
          "reserved_for_local_use": ""
        }
      ],
      "federal_tax_id": "",
      "ssn_or_ein": "",
      "patient_account_no": "",
      "accept_assignment_yes_no": "",
      "total_charge": "",
      "amount_paid": "",
      "balance_due": "",
      "physician_or_supplier_signature": "",
      "signature_date": "",
      "service_facility_location": "",
      "billing_provider_pin": "",
      "billing_provider_grp": ""
    }
    `
     
    const AddDenial = `You are an assistant with a goal of extracting particulat text from user message.
      - When user clicks on "Add denial reason":
      - Ask user, "Select one of the EMR bill" note: provide dropdown (With D in capital) like - Dropdown: ['Missing info EMR', 'Wrong codes EMR', 'Correct EMR']
              If user selects 'Missing info EMR' consider ${bill_1} as claim form
              If user selects 'Wrong codes EMR' consider ${bill_2} as claim form
              If user selects 'Correct EMR' consider ${bill_3} as claim form
      - Ask user, "Please provide the reason for denial provided by insurance company."
      - after user provides the reason,
        say, 'Please confirm denial reason: 
              "denialReason": {
                  "reason": "text provided by user"    
                }'
        note: do not use json ''' also provide buttons array like, (note: Buttons B should be capital) Buttons: ['Add reason', 'Cancel]
      - Ask user, "Do you need any other help?"
      `
    
    const VerifyClaimContext = `You are an assistant with a goal of verifying the EMR claim form to reduce denial of claims.
        You have access to submitted claim forms, patient details, services provided by physician/hospital, patient policy plan, patient diagnosis reports and previous denial reasons.
        Here is the json of previous denial reason - ${rules} note: this json has more weightage than policy json
        Here is the json for medical plan - ${policy}
        Here is the json for patient information - ${patient_david}
        Follow these instructions:
        - Only provide denial reasons if found. DO NOT provide the JSON of selected documents.
        - Ask user, "Are you sure you want to verify claim bill for David Clair." note: use html <b> tag for name of patient-"David Clair", also provide Buttons array (With B in capital) like - Buttons: ['Yes', 'Cancel']
        - If user clicks "Yes":
          - Ask user, "Select one of the EMR bill" note: provide dropdown (With D in capital) like - Dropdown: ['Missing info EMR', 'Wrong codes EMR', 'Correct EMR']
              If user selects 'Missing info EMR' consider ${bill_1} as claim form
              If user selects 'Wrong codes EMR' consider ${bill_2} as claim form
              If user selects 'Correct EMR' consider ${bill_3} as claim form
          - Ask user, "Select one of the Diagnosis Report" note: provide dropdown (With D in capital) like - Dropdown: ['Echocardiography', 'Cuff Tear']
              If user selects 'Cuff Tear' consider ${cuff_tear} as diagnosis report
              If user selects 'Echocardiography' consider ${Echocardiography} as diagnosis report
          - Ask user, "Select one of the services document" note: provide dropdown (With D in capital) like - Dropdown: ['ER & Ambulance', 'Syringe & Room']
              If user selects 'ER & Ambulance' consider ${ER_Ambulance} as services provided report
              If user selects 'Syringe & Room' consider ${Syringe_Room} as services provided report
          - Ask to user, "Do you want to upload authourization documents?" note: provide Buttons array(with B in Buttons capital) like - Buttons: ['Upload authorization documents', 'Cancel']
            If user says 'Upload authorization documents' then ask user to upload document like, "Choose file to upload" and store uploaded file name in ${filename1} note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Cancel']
            If user says 'file_name' store filename in ${filename1} and ${selectedFile} and say "file_name successfully uploaded" and ask following 
          - If claim gets denied, say,"Claim may get denied due to the following reasons: " and display if any reasons found by comparing details in selected claim form against patient details, diagnosis report, services document, ${policy}, ${rules} and ${authFile}.
            note: give priority to denial reason over policy. use html tags like <b>, <h4> for formatting.
            say, "Here is the corrected bill json" and refer ${blank_bill} to fill the corrected values so that it won't be denied and provide in json format. 
            note: put 2 whitespace for empty feilds. Do not remove any feilds from selected claim form.
            Ask user, "Do you want to see corrected bill?" note: provide Buttons array(with B in Buttons capital) like - Buttons: ['Show correct bill', 'Cancel']
                If user clicks 'Show correct bill', say "Here is the generated correct bill: " and show corrected bill. follow these instructions to generated correct bill:
                  consider ${blank_bill} to fill the correct values. 
                  do not change the keys in ${blank_bill} and put 2 whitespace for empty feilds. 
                  also consider common medical rates to fill "charge" feild with an estimated amount. 
                  make sure none of the reasons in ${rules} satisfy for the generated claim.
          - If the claim is not denied only return "Claim form won't be denied." 
          - Ask user, "Do you want Patient's bill" note: provide Buttons array(with B in Buttons capital) like - Buttons: ['Show Patient Bill', 'Cancel']
          if user clicks 'Show Patient Bill'. say, "Here is the patient's bill: " and show updated patient's bill. Follow these instructions to generate patient bill:
            refer ${policy} to calculate insurance payments.
            map the charges mentioned in selected EMR/EHR bill in below json.
            patient_payments is the total amount to be paid by patient
            bill should be in the json format as:
          {
            "account_number": "",
            "amount_due": "",
            "due_date": "",
            "medications":[{
                  "date": "",
                  "code": "",
                  "description": "",
                  "charges": "",
                  "insurance_payments": "",
                  "patient_payments": "",
                  "adjustments": "",
                  "balance": ""
              }],
            "statement_date": "",
            "invoice_number": "",
            "patient_name": "",
            "current":"",
            "over_30_days":"",
            "over_60_days":"",
            "over_90_days":"",
            "over_120_days":""
          }
          - Ask user, "Do you need any other help?"
          `
    
    const generateClaimContext = `
        You are an assistant with a goal of generating the EMR claim form such that it won't be denied.
        You have access to patient details, services provided by physician/hospital, patient policy plan, patient diagnosis reports and previous denial reasons.
        Here is the json for common denial reason - ${rules}
        Here is the json for medical plan - ${policy}
        Here is the json for patient information - ${patient_david}
        Follow these instructions:
        - Ask user, "Are you sure you want to generate claim bill for David Clair." note: use html <b> tag for name of patient-"David Clair", also provide Buttons array (With B in capital) like - Buttons: ['Yes', 'Cancel']
        - If user clicks "Yes":
          - Ask user, "Select one of the diagnosis report" note: provide dropdown (With D in capital) like - Dropdown: ['Cuff Tear', 'Echocardiography']
              If user selects 'Cuff Tear' consider ${cuff_tear} as diagnosis report
              If user selects 'Echocardiography' consider ${Echocardiography} as diagnosis report
          - Ask user, "Select one of the services document" note: provide dropdown (With D in capital) like - Dropdown: ['ER & Ambulance', 'Syringe & Room']
              If user selects 'ER & Ambulance' consider ${ER_Ambulance} as services provided report
              If user selects 'Syringe & Room' consider ${Syringe_Room} as services provided report
          - Check if any prior authorization documents are required for the selected diagnosis as per ${policy} 
              If any prior authorization documents are required, say to user, "Authorization documents require." ask user, "Do you want to upload authourization documents?" note: provide Buttons array(with B in Buttons capital) like - Buttons: ['Upload authorization documents', 'Cancel']
              If user says 'Upload authorization documents' then ask user to upload document like, "Choose file to upload" and store uploaded file name in ${filename1} note: also provide Buttons array(note: Buttons B should be capital) like this, Buttons: ['Cancel']
              If user says 'file_name' store filename in ${filename1} and ${selectedFile} and say "file_name successfully uploaded"
          - say, "Here is the generated insurance bill: " and follow these instructions to generate insurance bill:
            Consider selected details to fill ${blank_bill}. 
            Do not change the keys in ${blank_bill} and put 2 whitespace for empty feilds. 
            Consider prices mentioned in selected service report and ${authFile} to fill "charge" feild in generated claim as well as patient bill json below.
            and make sure none of the reasons in ${rules} satisfy for the generated claim. 
            display generated claim form in json format also, note: provide Buttons array(with B in Buttons capital) like - Buttons: ['Show Patient Bill', 'Cancel']
          - Ask user, "Do you want Patient's bill" note: provide Buttons array(with B in Buttons capital) like - Buttons: ['Show Patient Bill', 'Cancel']
            if user clicks 'Show Patient Bill'. say, "Here is the patient's bill: " and show updated patient's bill. Follow these instructions to generate patient bill:
              refer ${policy} to calculate insurance payments.
              map the charges mentioned in selected EMR/EHR bill in below json.
              balance is the total amount to be paid by patient
              bill should be in the json format as:
            {
              "account_number": "",
              "amount_due": "",
              "due_date": "",
              "medications":[{
                    "date": "",
                    "code": "",
                    "description": "",
                    "charges": "",
                    "insurance_payments": "",
                    "patient_payments": "",
                    "adjustments": "",
                    "balance": ""
                }],
              "statement_date": "",
              "invoice_number": "",
              "patient_name": "",
              "current":"",
              "over_30_days":"",
              "over_60_days":"",
              "over_90_days":"",
              "over_120_days":""
            }
          - Ask user, "Do you need any other help?"
          `;

  const getCompletionFrommessagesa = async (ncontext) => {
    if (
      !demo.toLowerCase().includes("start new conversation") &&
      !demo.toLowerCase().includes("reset") &&
      !demo.toLowerCase().includes("exit chat")
    ) {
      questionCount();
    }
    if (count < limit) {
      try {
        const body = {
          ncontext: ncontext,
          user_id: loggedInUserId,
        };
        let res = await axios.post(`${OPENAI_API}get_ai_res`, body);
        let assres = res.data.assres;
        let answer = res.data.assres;
        let pdfData;
        let reportres;
        setAnswer(answer);

        if (assres !== null) {
          if (assres.includes("checkbox")) {
            checkboxtable = assres.split("Buttons:")[0];
          }
          if (assres.includes("userDoc-data")) {
            await extractDocumentjson(assres);
          }

          if (assres.includes("name of the patient")) {
            setTimeout(() => {
              let pName = response;
              getPatientExist(pName);
            }, 5000);
          }
          if (assres.includes("ask your questions")) {
            console.log("user question", response, demo);
          }
          if (
            demo === "Yes upload" ||
            demo === "Yes upload video" ||
            demo === "Yes, claim" || demo === "Upload Existing Image" || demo === "Upload authorization documents"
          ) {
            SetShowFileUpload(true);
          }
          if(assres.includes("successfully uploaded")){
            let a_url = await upload_authorization_file();
            let blob_url =a_url.split("?")[0];
            console.log("file url: ", blob_url);
            demo = `<p><a href = "${blob_url}" target = "_blank">authorization document</a></p>`
            let summary = await report("summarize the document",blob_url);
            console.log("file data: ", summary);
            setAuthFile(summary);
          }
          if(demo === "Cuff Tear"){
            demo = `<span><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/cuff_tear.pdf" target="_blank">Cuff Tear</a></span>`
          }     
          if(demo === "Echocardiography"){
            demo = `<p><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/echocardiography.pdf" target = "_blank">Echocardiography</a></p>`
          }
          if(demo === "ER & Ambulance"){
            demo = `<p><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/ER_ambulance_services.pdf" target = "_blank">ER & Ambulance</a></p>`
          }
          if(demo === "Syringe & Room"){
            demo = `<p><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/Syringe_Room.pdf" target = "_blank">Syringe & Room</a></p>`
          }
          if(demo === "Missing info EMR"){
            demo = `<p><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/BILL 22.pdf" target = "_blank">Missing info EMR</a></p>`
          }
          if(demo === "Wrong codes EMR"){
            demo = `<p><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/BILL 11.pdf" target = "_blank">Wrong codes EMR</a></p>`
          }
          if(demo === "Correct EMR"){
            demo = `<p><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/bill_new.pdf" target = "_blank">Correct EMR</a></p>`
          }
          if (demo.includes("Continue")) {
            SetShowFileUpload(false);
            // setSelectedFile(null);
          }
          if (demo === "Continue upload" || demo.includes("file_name")) {
            SetShowFileUpload(false);
            await handleUploadVideoAPI(10);
          }
          if (demo.includes("image_name")){
            await handleUploadVideoAPI(9);
          }
          if (
            demo.includes("Process Claim") ||
            demo.includes("services_name")
          ) {
            SetShowFileUpload(false);
            reportres = await handleReportSelectAPI();
            // setSelectedFile(null);
          }
          if (assres.toLowerCase().includes("revenue code")) {
            console.log("asses res for Show bill", assres);
            let pdfres = assres;
            const body = {
              payload: pdfres,
            };
            pdfData = await axios.post(
              `${OPENAI_API}generate_insurance_claim_pdf`,
              body
            );

            console.log("resdata...", pdfData.data.pdf_url);
            let nContext = [
              ...context,
              {
                role: "system",
                content: `Here is your pdf link ${pdfData.data.pdf_url}`,
              },
            ];
            setContext(nContext);
          }
          if (demo === "I confirm") {
            console.log("PendingSecondOpinionjson", PendingSecondOpinionjson);
            if (PendingSecondOpinionjson != null) {
              sendResponseToSecondOpinionAPI();
            }
            if (SecondOpinionjson != null) {
              handleFileSelectAPI();
            }
            if (SecondOpinionjson1 != null) {
              handleFileSelectAPI_PHI();
            }
          }
          let doc;
          if (assres.includes("withphi-data")) {
            await extractpatientidjson(assres);
            doc = await getAllDocumentsAPI();
          }
          if (assres.includes("denialReason")){
            await extractdenialjson(assres); 
            console.log("denial_reasons", res);          
          }
          if(assres.includes("Here is the corrected")){
            let new_pdf_url = await extractbill(assres);
            console.log("new_pdf_url", new_pdf_url);
            assres = new_pdf_url
          }
          if(assres.includes("Here is the generated")){
            let new_pdf_url = await extractbill(assres);
            let new_bill_url = await get_bill_url();
            let url = `<p><a href = "${new_bill_url.data.pdf_url}" target = "_blank">Generated Bill</a></p>`
            let answer = "Here is the link for the generated bill: \n" + url + "\n Buttons: ['Show Patient Bill', 'Cancel']";
            assres = answer;
          }
          if(assres.includes("Here is the patient's")){
            let new_pdf_url = await extractbill(assres);
            console.log("new_pdf_url", new_pdf_url);
            let new_bill_url = await get_bill_patient();
            let url = `<p><a href = "${new_bill_url.data.pdf_url}" target = "_blank">Patient Bill</a></p>`
            let answer = "Here is the link for patient's bill: \n" + url + "Do you need any other help?";
            assres = answer;
          }
          if (demo === "Cancel") {
            SetShowFileUpload(false);
          }
          if (assres.includes("secondOpinion-data")) {
            SecondOpinionjson = extractsecondOpinionjson(assres);
            console.log("Json: ", SecondOpinionjson);
          }
          if (assres.includes("secondOpinion-data-PHI")) {
            SecondOpinionjson1 = extractsecondOpinionjson(assres);
            console.log("Json: ", SecondOpinionjson1);
          }
          if (
            assres.includes("dictation-data") ||
            assres.includes("video-data")
          ) {
            let patientID = await extractpatientidjson(assres);
            console.log("patient_id", patientID);
          }
          if (assres.includes("pendingReferalOpinion-data")) {
            PendingSecondOpinionjson = extractpendingsecondOpinionjson(assres);
          }
          if (assres.toLowerCase().includes("dropdown:")) {
            let dropdownopt = extractDropdownopt(assres);
            SetDropdownNames(dropdownopt);
          }
          if (assres.includes("uploadVideo-data")) {
            UploadVideojson = extractuploadVideojson(assres);
            console.log("Json video: ", UploadVideojson);
          }
          if (assres.includes("Do you need any other help?")) {
            setIsButtonDisabled((prevState) => !prevState);
            // setIsButtonDisabled(false)
            specialistName = "";
            uploadVideoFileUrl = "";
            SetPdfUrl([]);
            setClickedButton("");
            setmessagesa([]);
            SetbuttonNames([]);
            SetDropdownNames([]);
            SetShowFileUpload(false);
            setThreadButtonClicked(false);
            setContext(initialContext);
            setIsGeneralSearch(false);
            Setisfaqbutton(false);
            Setisexpbutton(false);
            SetisOtherQue(false);
            SetisVideoQue(false);
            setSelectedFile(null);
            file = "";
          }
          SettextBeforeButtons(assres.split("Buttons:")[0]);
          let textBeforeBut = "";
          if (assres.toLowerCase().includes("dropdown:")) {
            textBeforeBut = assres.toLowerCase().split("dropdown:")[0];
          } else if (assres.includes("pendingReferalOpinion-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```");
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("secondOpinion-data")) {
            let test = assres.split("Buttons:")[0];
            console.log("test...", test);
            const splitString = test.split("```");
            console.log("splitString...", splitString);
            textBeforeBut = splitString[splitString.length - 1].trim();
            console.log("textBeforeBut...", textBeforeBut);
          } else if (assres.includes("secondOpinion-data-PHI")) {
            let test = assres.split("Buttons:")[0];
            console.log("test...", test);
            const splitString = test.split("```");
            console.log("splitString...", splitString);
            textBeforeBut = splitString[splitString.length - 1].trim();
            console.log("textBeforeBut...", textBeforeBut);
          } else if (assres.includes("uploadVideo-data")) {
            let test = assres.split("Buttons:")[0];
            const splitString = test.split("```");
            textBeforeBut = splitString[splitString.length - 1].trim();
          } else if (assres.includes("userDoc-data")) {
            textBeforeBut = assres.split('"userDoc-data":')[0];
          } else if (assres.includes("Buttons:")) {
            textBeforeBut = assres.split("Buttons:")[0];
          } else {
            textBeforeBut = assres;
          }
          let userMessage;
          let newContext;
          if (assres.toLowerCase().includes("revenue code")) {
            userMessage = { text: response, user: true };
            newContext = [
              ...ncontext,
              { role: "assistant", content: assres },
              {
                role: "system",
                content: `Here is your pdf link ${pdfData.data.pdf_url}`,
              },
            ];
          } else if (response !== "") {
            userMessage = { text: response, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres }];
          } else if (demo.includes("services_name")) {
            userMessage = { text: demo, user: true };
            newContext = [
              ...ncontext,
              { role: "assistant", content: assres },
              {
                role: "system",
                content:
                  "Services provided by physician and their units as follows: \n" +
                  reportres,
              },
            ];
          } else if (demo === "Yes" || assres.includes("withphi-data")) {
            console.log("doc called");
            userMessage = { text: demo, user: true };
            newContext = [
              ...ncontext,
              { role: "assistant", content: assres },
              {
                role: "system",
                content: `patient documents are: ${JSON.stringify(doc)}`,
              },
            ];
          } else {
            userMessage = { text: demo, user: true };
            newContext = [...ncontext, { role: "assistant", content: assres }];
          }
          let aiResponse = { text: textBeforeBut, user: false };
          if (demo.includes("New") || demo.includes("Exit")) {
            setmessagesa([userMessage, aiResponse]);
            // //speak({ text: aiResponse.text, voice: voices[4] });
          } else {
            setmessagesa([...messagesa, userMessage, aiResponse]);
            // //speak({ text: aiResponse.text, voice: voices[4] });
          }
          if (assres.includes("Button")) {
            var buttonN = extractButtonNames(assres);
            SetbuttonNames(buttonN);
          }
          setContext(newContext);
          setResponse("");
          if (!threadApiCalled) {
            const obj = { question: demo, email: loggedInUserEmail };
            const add_thread = await axios.post(`${ACS_API}send_thread`, obj);
            // const add_thread = await axios.post(`http://127.0.0.1:5000/send_thread`, obj);
            thread_id = add_thread.data.new_row.Id;
            setThreadApiCalled(true);
            const response = await axios.get(`${ACS_API}get_all_threads/${loggedInUserEmail}`);
            // const response = await axios.get(`http://127.0.0.1:5000/get_all_threads/${loggedInUserEmail}`);
            categorizeThreads(response.data);
          }
          if (!(demo === "Start New Conversation")) {
            send_msg_func(demo, textBeforeBut, thread_id);
          }
        }
      } catch (error) {
        console.error("Error fetching AI response", error);
      }
    } else {
      let userMessage = { text: "", user: true };
      let aiResponse = {
        text: "You have reached your daily question limit",
        user: false,
      };
      setmessagesa([userMessage, aiResponse]);
      // //speak({ text: aiResponse.text, voice: voices[4] });
    }
  };

  useEffect(() => {
    if (messagesa.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesa]);

  const questionCount = async () => {
    let bodyc = { user_email: loggedInUserEmail };
    const resdata = await axios.post(
      `${OPENAI_API}ask`,
      // "http://127.0.0.1:5000/ask",
      bodyc
    );
    qcount = resdata.data.count;
    setCount(qcount);
  };

  const handleFileSelectAPI = async () => {
    try {
      let obj = new FormData();
      obj.append("loggedInUserId", SecondOpinionjsonObject.loggedInUserId);
      obj.append("companySlug", SecondOpinionjsonObject.companySlug);
      obj.append("physicianType", "1");
      obj.append("physicianId", SecondOpinionjsonObject.selectedPhysicianId);
      obj.append("specialtyId", SecondOpinionjsonObject.specialty);
      obj.append("subSpecialtyId", SecondOpinionjsonObject.subSpecialty);
      obj.append("emailTxt", SecondOpinionjsonObject.emailLogTxt);
      obj.append("requestingPhyMobile",localStorage.getItem("loggedInUserMobile"));
      obj.append("requestingPhyEmail", loggedInUserEmail);
      obj.append("reuestTime", SecondOpinionjsonObject.reuestTime);
      obj.append("userOptionId", "2");
      obj.append("docType", "11");
      obj.append("assignedDelegateId", "undefined");
      obj.append("isDelegated", "0");
      obj.append("selectedDelegateId", SecondOpinionjsonObject.loggedInUserId);
      obj.append("fileKey1", selectedFile1);
      obj.append("fileKey2", selectedFile2);
      obj.append("fileKey3", selectedFile3);

      let apiurlphp = phpbaseurl + ApiHelper.php_docotr2doctorEmail02Api;
      let result = await axios.post(
        // "https://api.medrecordexchange.com/dev/docotr2doctorEmail_Chat.php",
        `${apiurlphp}`,
        obj
      );
      console.log("result of expert opinion", result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileSelectAPI_PHI = async () => {
    console.log("SecondOpinionjsonObject in api...", SecondOpinionjsonObject);
    try {
      let obj = new FormData();
      obj.append("loggedInUserId", SecondOpinionjsonObject.loggedInUserId);
      obj.append("companySlug", SecondOpinionjsonObject.companySlug);
      obj.append("physicianType", "1");
      obj.append("physicianId", SecondOpinionjsonObject.selectedPhysicianId);
      obj.append("specialtyId", SecondOpinionjsonObject.specialty);
      obj.append("subSpecialtyId", SecondOpinionjsonObject.subSpecialty);
      obj.append("fileDesTxt", SecondOpinionjsonObject.fileDesTxt);
      obj.append("docType", "11");
      obj.append("userOptionId", SecondOpinionjsonObject.userOptionId);
      obj.append("selectedDocsId", SecondOpinionjsonObject.selectedDocsId);
      obj.append("hippaFileDesTxt", null);
      obj.append("showNewFile", "0");
      obj.append("attachmentDocObj", null);
      obj.append(
        "requestingPhyMobile",
        localStorage.getItem("loggedInUserMobile")
      );
      obj.append("requestingPhyEmail", loggedInUserEmail);
      obj.append("reuestTime", SecondOpinionjsonObject.reuestTime);
      obj.append(
        "demographicUserId",
        SecondOpinionjsonObject.demographicUserId
      );
      obj.append("assignedDelegateId", "undefined");
      obj.append("isDelegated", "0");
      obj.append("selectedDelegateId", SecondOpinionjsonObject.loggedInUserId);
      let apiurlphp = phpbaseurl + ApiHelper.php_docotr2doctorEmail01Api;
      let result = await axios.post(
        // "https://api.medrecordexchange.com/dev/docotr2doctorEmail_Chat.php",
        `${apiurlphp}`,
        obj
      );
      console.log("result of expert opinion", result);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleFileSelect = (event) => {
    file = event.target.files && event.target.files[0];
    console.log("file in file select", file);
    if (file) {
      fileNames = file.name;
      setFilename1(fileNames);
      if (demo === "Yes upload video") {
        demo = `file_name ${fileNames}`;
      } else if (demo === "Yes, claim") {
        demo = `services_name ${fileNames}`;
      }  else if (demo === "Upload authorization documents"){
        demo = `authorization document: ${fileNames}`
      }
      let nContext = [
        ...context,
        { role: "system", content: `You have selected file name ${fileNames}` },
        { role: "user", content: demo },
      ];
      setContext(nContext);
      setSelectedFile(file);
      const userMessage = { text: demo, user: true };
      let aiResponse = { text: "Please wait...", user: false };
      setmessagesa((prevmessagesa) => [
        ...prevmessagesa,
        userMessage,
        aiResponse,
      ]);
      SetShowFileUpload(false);
      SetbuttonNames([]);
      if (filename1 === undefined || filename1 === null) {
        setFilename1(fileName);
        setSelectedFile1(file);
      } else if (filename2 === undefined || filename2 === null) {
        setFilename2(file.name);
        setSelectedFile2(file);
      } else if (filename3 === undefined || filename3 === null) {
        setFilename3(file.name);
        setSelectedFile3(file);
      }
      getCompletionFrommessagesa(nContext);
    }
  };

  const initialquestionCount = async () => {
    let bodyc = { user_email: loggedInUserEmail };
    const resdata = await axios.post(
      `${OPENAI_API}get_initial_count_limit`,
      // "http://127.0.0.1:5000/get_initial_count_limit",
      bodyc
    );
    qcount = resdata.data.question_count;
    limit = resdata.data.limit;
    // qcount = 1;
    // limit = 1000;
    setCount(qcount);
  };

  const ClaimPdf = async () => {};

  const handleSendMessage = async () => {
    if (response.trim() !== "") {
      // setIsMicrophoneOn(false);
      let prompt = response;
      demo = response;
      setResponse("");
      if (!isotherQue && !isvideoQue) {
        SetbuttonNames([]);
      }
      SetDropdownNames([]);
      let nContext = [];
      if (!isfaqbutton && prompt.toLowerCase().includes("second opinion")) {
        setClickedButton("second opinion");
        demo = "second opinion";
        if (!isexpbutton) {
          Setisexpbutton(true);
          nContext = [
            { role: "system", content: SecondOpinionCOntext },
            { role: "user", content: prompt },
          ];
        } else {
          nContext = [...context, { role: "user", content: prompt }];
        }
        setIsGeneralSearch(false);
        Setisfaqbutton(false);
        Setisvdobutton(false);
        // Setisexpbutton(true)
      } else if (!isfaqbutton && prompt.toLowerCase().includes("task")) {
        setClickedButton("view pending tasks");
        setIsButtonDisabled(false);
        nContext = [
          { role: "system", content: pendingTaskContext },
          { role: "user", content: prompt },
        ];
        Setisexpbutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisvdobutton(false);
      } else if (prompt.toLowerCase().includes("faq")) {
        setClickedButton("faq");
        nContext = [
          { role: "system", content: FAQ_Document },
          { role: "user", content: prompt },
        ];
        Setisfaqbutton(true);
        Setisexpbutton(false);
        Setisvdobutton(false);
      } else if (
        !isfaqbutton &&
        /\b(?:patient.*details|details.*patient|active.*patient)\b/i.test(
          prompt.toLowerCase()
        )
      ) {
        setClickedButton("patient details");
        nContext = [
          { role: "system", content: Patient_details },
          { role: "user", content: prompt },
        ];
        Setisexpbutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisvdobutton(false);
      } else if (prompt.toLowerCase().includes("upload video")) {
        setClickedButton("upload video");
        nContext = [
          { role: "system", content: uploadVideoContext },
          { role: "user", content: prompt },
        ];
        Setisexpbutton(false);
        Setisfaqbutton(false);
        setIsGeneralSearch(false);
        Setisvdobutton(true);
      } else if (
        prompt.toLowerCase().includes("top 5 documents") ||
        prompt.toLowerCase().includes("see my documents") ||
        prompt.toLowerCase().includes("documents")
      ) {
        SetbuttonNames([
          "Compare",
          "Summarize",
          "Anatomical Abnormalities",
          "Other Question",
          "Reset",
        ]);
        let aiResponse = { text: html_table, user: false };
        let userMessage = { text: demo, user: true };
        setmessagesa([...messagesa, userMessage, aiResponse]);
      } else if (
        !isfaqbutton &&
        (prompt.toLowerCase().includes("pending opinion") ||
          prompt.toLowerCase().includes("pending opinions") ||
          prompt
            .toLocaleLowerCase()
            .includes("Review list of pending opinions"))
      ) {
        setClickedButton("respond expert 2nd opinion");
        nContext = [
          { role: "system", content: pendingReferalOpinion },
          { role: "user", content: prompt },
        ];
        setIsGeneralSearch(false);
        Setisexpbutton(false);
      } else if (
        prompt.toLowerCase().includes("exit chat") ||
        prompt.toLowerCase().includes("thanks")
      ) {
        nContext = [
          { role: "system", content: newChat },
          { role: "user", content: "New Chat" },
        ];
        Setisfaqbutton(false);
        SetDropdownNames([]);
        setClickedButton("");
        setIsGeneralSearch(false);
        Setisexpbutton(false);
      } else if (prompt.toLowerCase().includes("stop dictation")) {
        stopRecording();
        nContext = [
          ...context,
          // {role: "system", content: "here is document summary:" +r5},
          { role: "user", content: "Stop Dictation" },
        ];
        SetbuttonNames([]);
      } else {
        nContext = [...context, { role: "user", content: prompt }];
      }

      if (
        !prompt.toLowerCase().includes("top 5 documents") &&
        !prompt.toLowerCase().includes("see my documents") &&
        !prompt.toLowerCase().includes("my documents")
      ) {
        setContext(nContext);
        const userMessage = { text: prompt, user: true };
        let aiResponse = { text: "Please wait...", user: false };
        setmessagesa((prevmessagesa) => [
          ...prevmessagesa,
          userMessage,
          aiResponse,
        ]);
        //speak({ text: aiResponse.text, voice: voices[4] });
        if (
          isGeneralSearch &&
          !prompt.includes("Hi") &&
          !prompt.includes("exit chat") &&
          prompt.includes("?")
        ) {
          SetbuttonNames([]);
          console.log("in general sql search api called");
          try {
            if (prompt.toLowerCase().includes("patient list")) {
              prompt = `Give the me the list of patients full name who are registered under physician id ${loggedInUserId} from user_doctors and user_login table.?`;
            }
            const body = {
              prompt: prompt,
              loggedInUserId: loggedInUserId,
              baseURL: baseURL,
              user_id: loggedInUserId,
            };
            const res = await axios.post(
              `${OPENAI_API}gen_search`,
              // "http://127.0.0.1:5000/gen_search",
              body
            );
            let ans = res.data.answer;
            setmessagesa((prevmessagesa) => {
              const updatedmessagesa = prevmessagesa.slice(0, -1);
              return [...updatedmessagesa, { text: ans, user: false }];
            });
            //speak({ text: ans , voice: voices[4]});
            questionCount();
          } catch (err) {
            console.error("Error:", err);
          }
        } else if (isfaqbutton && !prompt.includes("thanks")) {
          try {
            const body = {
              question: response,
              loggedInUserRoleId: loggedInUserRoleId,
            };
            try {
              const res = await axios.post(`${OPENAI_API}faq_search`, body);
              let ans = res.data.answer;
              aiResponse = { text: ans, user: false };
              setmessagesa((prevmessagesa) => {
                const updatedmessagesa = prevmessagesa.slice(0, -1);
                return [...updatedmessagesa, aiResponse];
              });
              //speak({ text: aiResponse.text , voice: voices[4]});
              send_msg_func(response, ans, thread_id);
              questionCount();
            } catch (error) {
              if (error.response && error.response.status === 500) {
                setmessagesa((prevmessagesa) => {
                  aiResponse = {
                    text: "Data not available in FAQ, please ask another question",
                    user: false,
                  };
                  const updatedmessagesa = prevmessagesa.slice(0, -1);
                  return [...updatedmessagesa, aiResponse];
                });
                //speak({ text: aiResponse.text ,voice: voices[4]});
              }
            }
          } catch (err) {
            console.error("Error:", err);
          }
        } else if (isotherQue && !prompt.includes("thanks")) {
          // const r1 = await report(prompt, pdfUrl);
          let r1;
          if (pdfUrl.length > 0) {
            r1 = await report(prompt, pdfUrl);
          }
          if (imageUrl.length > 0) {
            r1 = await report(prompt, imageUrl);
          }

          aiResponse = { text: r1, user: false };
          let userMessage = { text: prompt, user: true };
          setmessagesa([...messagesa, userMessage, aiResponse]);
          //speak({ text: aiResponse.text,voice: voices[4] });
          send_msg_func(demo, r1, thread_id);
          questionCount();
        } else if (isvideoQue && !prompt.includes("thanks")) {
          let r1;
          if (videoUrl.length !== 0) {
            r1 = await report(prompt, videoUrl);
          } else {
            r1 = await generateVideoURI(prompt);
          }
          aiResponse = { text: r1, user: false };
          let userMessage = { text: prompt, user: true };
          setmessagesa([...messagesa, userMessage, aiResponse]);
          //speak({ text: aiResponse.text,voice: voices[4] });
          send_msg_func(demo, r1, thread_id);
          questionCount();
        } else {
          try {
            getCompletionFrommessagesa(nContext); // Wait for completion
          } catch (error) {
            console.error("Error sending message", error);
          }
        }
      }
    }
  };

  function handleExitChat(event) {
    // event.preventDefault();
    if (!isButtonDisabled) {
      SweetAlertHelper.showConfirm(
        "Would you like to exit from the current chat context and initiate a new conversation?",
        () => {
          setIsButtonDisabled((prevState) => !prevState);
          specialistName = "";
          uploadVideoFileUrl = "";
          SetPdfUrl([]);
          setClickedButton("");
          setmessagesa([]);
          SetbuttonNames([]);
          SetDropdownNames([]);
          setThreadButtonClicked(false);
          setIsGeneralSearch(false);
          Setisfaqbutton(false);
          Setisexpbutton(false);
          SetShowFileUpload(false);
          SetisOtherQue(false);
          SetisVideoQue(false);
          setSelectedFile(null);
          demo = "Start New Conversation";
          let nContext = [
            { role: "system", content: newChat },
            { role: "user", content: "New Chat" },
          ];
          // setContext(nContext);
          setContext(initialContext);
          setThreadApiCalled(false);
          // Create messages for user action and AI response
          const userMessage = {
            text: "Start New Conversation",
            user: true,
          };
          let aiResponse = {
            text: "please wait...",
            user: false,
          };
          setmessagesa([userMessage, aiResponse]);
          //speak({ text: aiResponse.text, voice: voices[4] });
          getCompletionFrommessagesa(nContext); // Reset clicked button state
        }
      );
    }
  }

  useEffect(() => {}, [setClickedButton]);

  async function handleStaticButton(buttonName) {
    setActiveClicked(true);
    setClickedButton(buttonName);
    setIsButtonDisabled(false);
    let nContext;
    demo = buttonName;
    if (buttonName.toLowerCase() === "second opinion") {
      nContext = [
        { role: "system", content: SecondOpinionCOntext },
        { role: "user", content: "Second opinion" },
      ];
      Setisexpbutton(true);
      Setisfaqbutton(false);
    } else if (buttonName.toLowerCase() === "start dictation") {
      nContext = [
        { role: "system", content: dictationContext },
        { role: "user", content: "Start Dictation" },
      ];
      Setisfaqbutton(false);
    } else if (buttonName.toLowerCase() === "stop dictation") {
      nContext = [
        { role: "system", content: dictationContext },
        { role: "user", content: "Stop Dictation" },
      ];
      Setisfaqbutton(false);
    } else if (buttonName.toLowerCase() === "start recording") {
      nContext = [
        { role: "system", content: dictationContext },
        { role: "user", content: "Start Recording" },
      ];
      // Other state changes as needed
      startRecording(); // Function to handle the start of recording
    }
    // Handle "Stop Recording" command
    else if (buttonName.toLowerCase() === "stop recording") {
      nContext = [
        { role: "system", content: dictationContext },
        { role: "user", content: "Stop Recording" },
      ];
      // Other state changes as needed
      stopRecording(); // Function to handle the stop of recording
    } else if (buttonName.toLowerCase() === "Yes, Start Dictation") {
      nContext = [
        { role: "system", content: dictationContext },
        { role: "user", content: "Yes, Start Dictation" },
      ];
      // Other state changes as needed
      startRecording(); // Function to handle the stop of recording
    } else if (buttonName.toLowerCase() === "respond expert 2nd opinion") {
      nContext = [
        { role: "system", content: pendingReferalOpinion },
        { role: "user", content: "Respond Expert 2nd Opinion" },
      ];
      Setisfaqbutton(false);
      Setisexpbutton(false);
    } else if (buttonName.toLowerCase() === "faq") {
      nContext = [
        { role: "system", content: FAQ_Document },
        { role: "user", content: "FAQ" },
      ];
      Setisfaqbutton(true);
      Setisexpbutton(false); // Assuming this is a function to set a state or context for FAQ
    } else if (
      buttonName.toLowerCase() === "patient details" ||
      buttonName.toLowerCase().includes("active patient")
    ) {
      setClickedButton("patient details");
      nContext = [
        { role: "system", content: Patient_details },
        { role: "user", content: buttonName },
      ];
      Setisfaqbutton(false);
      Setisexpbutton(false);
    } else if (buttonName.toLowerCase() === "upload video") {
      setClickedButton("upload video");
      // nContext = [
      //   { role: "system", content: uploadVideoContext },
      //   { role: "user", content: prompt },
      // ];
      // Setisexpbutton(false);
      // Setisfaqbutton(false);
      setIsGeneralSearch(false);
      // Setisvdobutton(true);
      nContext = [
        { role: "system", content: uploadVideoContext },
        { role: "user", content: buttonName },
      ];
      Setisfaqbutton(false);
      Setisexpbutton(false);
      Setisvdobutton(true);
      // Assuming this is a function to set a state or context for FAQ
    } else if (buttonName.toLowerCase() === "insurance claim") {
      nContext = [
        { role: "system", content: generateClaimContext },
        { role: "user", content: "Insurance Claim" },
      ];
      Setisexpbutton(true);
      Setisfaqbutton(false);
    } else if (buttonName.toLowerCase() === "verify insurance claim") {
      nContext = [
        { role: "system", content: VerifyClaimContext },
        // { role: "system", content: insuranceClaimJsonContext },
        { role: "user", content: "verify insurance claim" },
      ];
      Setisexpbutton(true);
      Setisfaqbutton(false);
    } else if (buttonName.toLowerCase() === "add denial reason") {
      nContext = [
        { role: "system", content: AddDenial },
        // { role: "system", content: insuranceClaimJsonContext },
        { role: "user", content: "add denial reason" },
      ];
      Setisexpbutton(true);
      Setisfaqbutton(false);
    }

    setContext(nContext);
    const userMessage = {
      text: buttonName,
      user: true,
    };
    let aiResponse = {
      text: "please wait...",
      user: false,
    };
    setmessagesa([...messagesa, userMessage, aiResponse]);
    //speak({ text: aiResponse.text, voice: voices[4] });
    getCompletionFrommessagesa(nContext);
    SetbuttonNames([]);
  }

  useEffect(() => {
    // Scroll to the bottom when the component is initially rendered
    chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
  }, []);

  useEffect(() => {
    // Scroll to the last message when messages change
    if (messagesa.length > 0 && lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messagesa]);

  return (
    <div>
      {isLoading && (
        <div className="page-loader">
          <img src="../../assets/images/ajax-loader.gif" alt="loader" />
        </div>
      )}
      <div className="chat-container" ref={chatContainerRef}>
        <div
          className="nav-bar"
          style={{
            display: isSidebarOpen ? "block" : "none",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            {/* <h5 style={{ marginLeft: "11px" }}>
              {" "}
              <strong style={{ color: "blcak" }}>Chat History</strong>{" "}
            </h5> */}
            {/* <img src={sstlogo} alt="SSTLogo" style={{ width: "80px" }} /> */}
            <img
              src={sstlogo}
              alt="SSTLogo"
              className="spaced-img"
              style={{ width: "80px", height: "50px", margin: "0 20px" }}
              // style={{ width: "80px" }}
            />
            {/* <img
              src={spiralslogo}
              alt="spiralslogo"
              style={{ width: "80px", height: "50px" }}
            /> */}
          </div>

          <button
            // className="exist-button-history"
            className={`exist-button ${
              isButtonDisabled ? "disabled-class" : "enabled-class"
            }`}
            // className="send-button"
            onClick={handleExitChat}
            disabled={count >= limit && isButtonDisabled}
            style={{
              // marginLeft: "11px",
              // marginRight: "11px",
              width: "296px",
              padding: "7px",
              margin: "5px",
              background: "rgb(10, 49, 97)",
              border: "1px solid white",
              color: "white",
            }}
            title="Start new conversation"
          >
            <b>Start new Conversation</b>
          </button>

          <ul className="parent-nav-list">
            {todayThread.length > 0 && (
              <li>
                <strong style={{ color: "white" }}>Today</strong>
                {todayThread.map((thread, index) => (
                  <ul className="nav-list" key={index}>
                    <button
                      className={`thread_btn ${
                        activeThread === index ? "active-button" : ""
                      }`}
                      onClick={() => loadThread(thread, index)}
                    >
                      {hasMessages(thread.name) && (
                        <FontAwesomeIcon
                          icon={faMessage}
                          className="message-icon"
                        />
                      )}

                      {thread.name}
                    </button>
                  </ul>
                ))}
              </li>
            )}
            {last7DaysThreads.length > 0 && (
              <li>
                <strong style={{ color: "white" }}>Last 7 Days</strong>
                {last7DaysThreads.map((thread, index) => (
                  <ul className="nav-list" key={index}>
                    <button
                      className="thread_btn"
                      onClick={() => loadThread(thread, index)}
                    >
                      {hasMessages(thread.name) && (
                        <FontAwesomeIcon
                          icon={faMessage}
                          className="message-icon"
                        />
                      )}
                      {thread.name}
                    </button>
                  </ul>
                ))}
              </li>
            )}
            {olderThread.length > 0 && (
              <li>
                <strong style={{ color: "black" }}>Older Chats</strong>
                {olderThread.map((thread, index) => (
                  <ul className="nav-list" key={index}>
                    <button
                      className="thread_btn"
                      onClick={() => loadThread(thread, index)}
                    >
                      {hasMessages(thread.name) && (
                        <FontAwesomeIcon
                          icon={faMessage}
                          className="message-icon"
                        />
                      )}
                      {thread.name}
                    </button>
                  </ul>
                ))}
              </li>
            )}
          </ul>
        </div>
        <div className="chat-col lg-10">
          <div className="count-container bold-text">
            {count} of {limit}
          </div>
          <div className="bold-text" style={{alignSelf: "flex-end"}}>
          <p><a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/medical_plans_complete.pdf" target="_blank">Policy plans</a>
              &nbsp;&nbsp;&nbsp; <a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/Denialrules.txt" target="_blank">Denial rules</a>
              &nbsp;&nbsp;&nbsp; <a href = "https://blobtimesheet.blob.core.windows.net/insurancedocs/Patient_Information_David.pdf" target="_blank">Patient information</a></p>
          </div>
          {count >= limit && (
            <div className="count-container bold-text" style={{ color: "red" }}>
              You have reached your daily question limit
            </div>
          )}

          <div className="message-container">
            {messagesa.map((message, index) => (
              <div
                key={index}
                style={{
                  alignSelf: message.user ? "flex-end" : "flex-start",
                  backgroundColor: message.user ? "#e1ffc7" : "#f0f0f0",
                  borderRadius: "10px",
                  padding: "8px 12px",
                  marginBottom: "5px",
                  maxWidth: "80%",
                }}
              >
                {message.user ? (
                  <div
                 dangerouslySetInnerHTML={{ __html: message.text }}
               />
                ) : (
                  <>
                    {typeof message.text === "object" ? (
                      JSON.stringify(message.text)
                    ) : (
                      <>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faClipboard}
                            onClick={() =>
                              handleCopyToClipboard(message.text, index)
                            }
                            style={{ marginLeft: "5px", cursor: "pointer" }}
                            color={copiedIndex === index ? "green" : "#1c84c6"}
                            title={
                              copiedIndex === index ? "Copied!" : "Copy Text"
                            }
                          />
                          <FontAwesomeIcon
                            icon={
                              speaking && !isSpeechPaused ? faPause : faVolumeUp
                            }
                            onClick={handleVolumeIconClick(message.text)}
                            style={{
                              marginLeft: "5px",
                              cursor: "pointer",
                              color: "#1c84c6",
                            }}
                            title={
                              speaking && !isSpeechPaused ? "Pause" : "Listen"
                            }
                          />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FontAwesomeIcon icon={faRobot} /> &nbsp;
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {message.text.includes("<table") ? (
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: message.text,
                                }}
                              />
                            ) : (
                              message.text.split("\n").map((line, index) => (
                                <React.Fragment key={index}>
                                  <div
                                    dangerouslySetInnerHTML={{ __html: line }}
                                  />
                                </React.Fragment>
                              ))
                            )}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
              </div>
            ))}
            {dropdownNames.length > 0 && (
              <div>
                <select onChange={handleDropdownChange}>
                  <option value="">Select an option</option>
                  {dropdownNames.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            )}
            {showFileUpload && (
              <div>
                <input type="file" onChange={handleFileSelect} />
                {selectedFile && <p>Selected File: {selectedFile.name}</p>}
              </div>
            )}
            {(textBeforeButtons !== "" || html_table !== "") && (
              <div>
                <div style={{ display: "flex", flexWrap: "wrap", gap: "10px" }}>
                  {buttonNames.map((buttonName, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <button
                        className="send-button"
                        key={index}
                        onClick={async () => {
                          demo = buttonName;
                          // setResponse(buttonName);
                          let nContext;
                          if (buttonName === "Second opinion") {
                            nContext = [
                              { role: "system", content: SecondOpinionCOntext },
                              { role: "user", content: buttonName },
                            ];
                          }
                          else if (
                            buttonName === "Respond Expert 2nd Opinion"
                          ) {
                            nContext = [
                              {
                                role: "system",
                                content: pendingReferalOpinion,
                              },
                              { role: "user", content: buttonName },
                            ];
                          } else if (buttonName === "FAQ") {
                            nContext = [
                              { role: "system", content: FAQ_Document },
                              { role: "user", content: buttonName },
                            ];
                            Setisfaqbutton(true);
                          } else if (buttonName === "Patient details") {
                            nContext = [
                              { role: "system", content: Patient_details },
                              { role: "user", content: buttonName },
                            ];
                          } else if (buttonName === "Upload Video") {
                            nContext = [
                              { role: "system", content: uploadVideoContext },
                              { role: "user", content: buttonName },
                            ];
                          } else if (buttonName.includes("New")) {
                            nContext = [
                              { role: "system", content: newChat },
                              { role: "user", content: buttonName },
                            ];
                          } else if (buttonName.includes("Compare")) {
                            setIsLoading(true);
                            let aiResponse = {
                              text: "Please wait, I am processing reports...",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text,voice: voices[4] });
                            let r1;
                            if (pdfUrl.length === 2) {
                              r1 = await report("Compare the reports", pdfUrl);
                            } else {
                              r1 =
                                "Please select two pdf reports to compare. \n";
                            }
                            if (imageUrl.length > 0) {
                              // r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", imageUrl);
                              r1 =
                                "Please select two pdf reports to compare, I am unable to compare images. \n";
                            }
                            if (videoUrl.length > 0) {
                              // r1 = await report("For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points", imageUrl);
                              r1 =
                                "Please select two pdf reports to compare, I am unable to compare videos. \n";
                            }
                            // const r1 = await report("Compare the reports", pdfUrl);
                            let aire =
                              r1 +
                              "\n\nIf you have any question on this report please enter the question or else click on Reset button to choose other reports.";
                            aiResponse = {
                              text: aire,
                              user: false,
                            };
                            userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text ,voice: voices[4]});
                            // SetPdfUrl([])
                            setIsLoading(false);
                            SetisOtherQue(true);
                            SetisVideoQue(false);
                            send_msg_func(demo, aire, thread_id);
                            questionCount();
                          } else if (buttonName === "Detailed Summary") {
                            setIsLoading(true);
                            let aiResponse = {
                              text: "Please wait, I am processing video...",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text ,voice: voices[4]});
                            videosum = await generateVideoURI(
                              "if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points"
                            );
                            videosum +=
                              "\n\nIf you have any other question on this video, please enter in below box.";
                            aiResponse = {
                              text: videosum,
                              user: false,
                            };
                            userMessage = { text: demo, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            setIsLoading(false);
                            //speak({ text: aiResponse.text ,voice: voices[4]});
                            send_msg_func(demo, videosum, thread_id);
                            questionCount();
                            SetisVideoQue(true);
                            SetisOtherQue(false);
                            SetShowFileUpload(false);
                            setThreadButtonClicked(false);
                          } else if (
                            buttonName === "Anatomical Abnormalities"
                          ) {
                            setIsLoading(true);
                            let aiResponse = {
                              text: "Please wait, I am processing video...",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text ,voice: voices[4]});
                            videosum = await generateVideoURI(
                              "What anatomical abnormalities are evident in this visual examination?"
                            );
                            videosum +=
                              "\n\nIf you have any other question, please enter in below box.";
                            aiResponse = {
                              text: videosum,
                              user: false,
                            };
                            userMessage = { text: demo, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            setIsLoading(false);
                            //speak({ text: aiResponse.text ,voice: voices[4]});
                            send_msg_func(demo, videosum, thread_id);
                            questionCount();
                            SetisVideoQue(true);
                            SetisOtherQue(false);
                            SetShowFileUpload(false);
                            setThreadButtonClicked(false);
                          } else if (buttonName.includes("Ask Question")) {
                            let aiResponse = {
                              text: "Please type your Question in the box below",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text, voice: voices[4] });
                            SetisVideoQue(true);
                            SetisOtherQue(false);
                            questionCount();
                          } else if (buttonName.includes("Summarize")) {
                            setIsLoading(true);
                            let aiResponse = {
                              text: "Please wait, I am processing reports...",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text, voice: voices[4] });
                            let r1;
                            if (pdfUrl.length > 0) {
                              if (pdfUrl.length !== 1) {
                                r1 =
                                  "Please select only one document to summarize!";
                              } else {
                                r1 = await report(
                                  "Summarize the report",
                                  pdfUrl
                                );
                                SetisOtherQue(true);
                              }
                            }
                            if (imageUrl.length > 0) {
                              if (imageUrl.length !== 1) {
                                r1 =
                                  "Please select only one document to summarize!";
                              } else {
                                r1 = await report(
                                  "For the the given image if it is MRI/CT scan or  X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points",
                                  imageUrl
                                );
                                SetisOtherQue(true);
                              }
                            }
                            if (videoUrl.length > 0) {
                              console.log("video url selected ");
                              r1 = await report(
                                "if it is MRI/CT scan or X-ray, provide an analysis from radiologist perspective and give a analysis if any diagnosis is required or not, Display the information as HTML paragraph only using <b>, only <h6> and <ul> for bullet points",
                                videoUrl
                              );
                              console.log("r1 of video", r1);
                              SetisVideoQue(true);
                            }
                            let aire =
                              r1 +
                              "\n \nIf you have any question on this report please enter the question or else click on Reset button to choose other reports.";
                            aiResponse = { text: aire, user: false };
                            userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            setIsLoading(false);
                            //speak({ text: aiResponse.text,voice: voices[4] });
                            // SetPdfUrl([])

                            send_msg_func(demo, aire, thread_id);
                            questionCount();
                          } else if (buttonName.includes("Show Summary") || buttonName.includes("Show Prescription")) { 
                        setIsLoading(true)
                        let aiResponse;
                        if (buttonName.includes("Show Summary")){
                          aiResponse = {
                            text: "Please wait, I am generating summary of dictation...",
                            user: false,
                        };
                        }
                        if (buttonName.includes("Show Prescription")){
                          aiResponse = {
                            text: "Please wait, I am generating prescription...",
                            user: false,
                          };
                          }
                        let userMessage = { text: buttonName, user: true };
                        SetbuttonNames([]);
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        //speak({ text: aiResponse.text ,voice: voices[4]});  
                        let videosum = await returnSummary(audioBlob); 
                        console.log("videosum", videosum)
                        aiResponse = {
                        text: videosum,
                        user: false,
                        };
                        userMessage = { text: demo, user: true };
                        setmessagesa([...messagesa, userMessage, aiResponse]);
                        setIsLoading(false)
                        //speak({ text: aiResponse.text ,voice: voices[4]});  
                        send_msg_func(demo, videosum, thread_id);
                        questionCount();                        
                        setIsButtonDisabled((prevState) => !prevState);
                        setClickedButton("");                        
                        SetShowFileUpload(false);
                        setThreadButtonClicked(false);
                        setContext(initialContext);
                        // setThreadApiCalled(false);
                      } else if (buttonName.includes("Other Question")) {
                            let aiResponse = {
                              text: "Please type your Other Question in the box below",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text, voice: voices[4] });
                            SetisOtherQue(true);
                            SetisVideoQue(false);
                            questionCount();
                          } else if (buttonName.includes("Reset")) {
                            let aiResponse = {
                              text: checkboxtable,
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            setmessagesa([
                              ...messagesa,
                              userMessage,
                              aiResponse,
                            ]);
                            //speak({ text: aiResponse.text, voice: voices[4] });
                            send_msg_func(demo, checkboxtable, thread_id);
                            SetPdfUrl([]);
                            SetImageUrl([]);
                            setVideoUrl([]);
                          } else if (buttonName === "Yes, Start Dictation") {
                            nContext = [
                              ...context,
                              { role: "user", content: buttonName },
                            ];
                            startRecording();
                          } else if (buttonName === "Stop Dictation") {
                            nContext = [
                              ...context,
                              { role: "system", content: `${summaryValue}` },
                              { role: "user", content: buttonName },
                            ];
                            await stopRecording();
                          } 
                          else if(buttonName === "Show correct bill"){
                            setIsLoading(true)
                            let aiResponse = {
                              text: "Please wait, I am generating correct bill...",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            SetbuttonNames([]);
                            setmessagesa([...messagesa, userMessage, aiResponse]);
                            let new_bill_url = await get_bill_url();
                            console.log("new_bill_url", new_bill_url.data);
                            let url = `<p><a href = "${new_bill_url.data.pdf_url}" target = "_blank">Correct Bill</a></p>`
                            let answer = "Here is the link for correct bill: \n" + url ;
                            aiResponse = {
                              text: answer,
                              user: false,
                              };
                            userMessage = { text: demo, user: true };
                            setmessagesa([...messagesa, userMessage, aiResponse]);
                            setIsLoading(false)  
                            send_msg_func(demo, answer, thread_id);
                            questionCount();                        
                          } 
                          else if(buttonName === "Add reason"){
                            let aiResponse = {
                              text: "Please wait... ",
                              user: false,
                            };
                            let userMessage = { text: buttonName, user: true };
                            SetbuttonNames([]);
                            setmessagesa([...messagesa, userMessage, aiResponse]);
                            let updated_url = await get_rules_url();
                            let updated_rules =`<p><a href = "${updated_url}" target = "_blank">Denial Rules</a></p>`
                            let answer = "Reason added successfully. Here is the link for rule book: \n" + updated_rules + "\nDo you need any other help?";
                            aiResponse = {
                              text: answer,
                              user: false,
                              };
                            userMessage = { text: demo, user: true };
                            setmessagesa([...messagesa, userMessage, aiResponse]);
                            setIsLoading(false)  
                            send_msg_func(demo, answer, thread_id);
                            questionCount(); 
                            setIsButtonDisabled((prevState) => !prevState);
                            setClickedButton("");                        
                            SetShowFileUpload(false);
                            setThreadButtonClicked(false);
                            setContext(initialContext);                       
                          }
                          else {
                            nContext = [
                              ...context,
                              { role: "user", content: buttonName },
                            ];
                          }
                          if (
                            buttonName !== "Compare" &&
                            buttonName !== "Summarize" &&
                            buttonName !== "Other Question" &&
                            buttonName !== "Reset" &&
                            buttonName !== "Detailed Summary" &&
                            buttonName !== "Anatomical Abnormalities" &&
                            buttonName !== "Show Summary" &&
                            buttonName !== "Ask Question" &&
                            buttonName !== "Show correct bill" &&
                            buttonName !== "Add reason" &&
                            buttonName !== "Show Prescription" 
                          ) {
                            setContext(nContext);
                            const userMessage = {
                              text: buttonName,
                              user: true,
                            };
                            let aiResponse = {
                              text: "please wait...",
                              user: false,
                            };
                            if (buttonName.includes("New")) {
                              setmessagesa([userMessage, aiResponse]);
                              //speak({ text: aiResponse.text,voice: voices[4] });
                            } else {
                              setmessagesa([
                                ...messagesa,
                                userMessage,
                                aiResponse,
                              ]);
                              //speak({ text: aiResponse.text, voice: voices[4] });
                            }
                            getCompletionFrommessagesa(nContext);
                            SetbuttonNames([]);
                          }
                        }}
                      >
                        {buttonName}
                        {/* {buttonName === "Stop Dictation" && isRecording && ` (Time Left: ${formatTimeLeft()})`} */}
                      </button>
                      {buttonName === "Stop Dictation" && isRecording && (
                        <span className="digital-clock">
                          {" "}
                          {`Time Elapsed: ${formatTimeLeft()}`}
                        </span>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            )}

            <div ref={lastMessageRef}></div>

            <button
              onClick={toggleSidebar}
              className="toggle-button"
              title={isSidebarOpen ? "Close Sidebar" : "Open Sidebar"}
            >
              <FontAwesomeIcon
                className="icon"
                icon={isSidebarOpen ? faChevronLeft : faChevronRight}
              />
            </button>
          </div>
          <div
            className="buttoArray"
            style={{ display: `${threadButtonClicked ? "none" : ""}` }}
          >
            {/* <button
              className={`send-button ${
                activeclicked && clickedButton === "second opinion"
                  ? "green-class"
                  : ""
              }`}
              onClick={() => handleStaticButton("second opinion")}
              disabled={
                count >= limit ||
                (clickedButton !== "" && clickedButton !== "second opinion") ||
                (activeclicked && clickedButton === "second opinion")
              }
              title=" Ask Second opinion"
            >
              Ask Second opinion
            </button> */}

            {/*<button
                className="send-button"
                onClick={() => handleStaticButton("view pending tasks")}
                disabled={count >= limit ||(clickedButton !== "" && clickedButton !== "view pending tasks")}
                title= "View Pending Tasks"
                >
                View Pending Tasks
                  </button>*/}

            {/* <button
              className={`send-button ${
                activeclicked && clickedButton === "respond expert 2nd opinion"
                  ? "green-class"
                  : ""
              }`}
              onClick={() => handleStaticButton("respond expert 2nd opinion")}
              disabled={
                count >= limit ||
                (clickedButton !== "" &&
                  clickedButton !== "respond expert 2nd opinion") ||
                (activeclicked &&
                  clickedButton === "respond expert 2nd opinion")
              }
              title="Respond Expert 2nd Opinion"
            >
              Respond Expert 2nd Opinion
            </button> */}

            {/* <button
              className={`send-button ${
                activeclicked && clickedButton === "faq" ? "green-class" : ""
              }`}
              onClick={() => handleStaticButton("faq")}
              disabled={
                count >= limit ||
                (clickedButton !== "" && clickedButton !== "faq") ||
                (activeclicked && clickedButton === "faq")
              }
              title="FAQ"
            >
              FAQ
            </button> */}

            {/* <button
              className={`send-button ${
                activeclicked && clickedButton === "patient details"
                  ? "green-class"
                  : ""
              }`}
              onClick={() => handleStaticButton("patient details")}
              disabled={
                count >= limit ||
                (clickedButton !== "" && clickedButton !== "patient details") ||
                (activeclicked && clickedButton === "patient details")
              }
              title="Patient details"
            >
              Patient details
            </button> */}
            {/* <button
              className={`send-button ${
                activeclicked && clickedButton === "upload video"
                  ? "green-class"
                  : ""
              }`}
              onClick={() => handleStaticButton("upload video")}
              disabled={
                count >= limit ||
                (clickedButton !== "" && clickedButton !== "upload video") ||
                (activeclicked && clickedButton === "upload video")
              }
              title="Upload Video"
            >
              Upload Video
            </button> */}
            <button
              className={`send-button ${
                activeclicked && clickedButton === "start dictation"
                  ? "green-class"
                  : ""
              }`}
              onClick={() => handleStaticButton("start dictation")}
              disabled={
                count >= limit ||
                (clickedButton !== "" && clickedButton !== "start dictation") ||
                (activeclicked && clickedButton === "start dictation")
              }
              title="Start Dictation"
            >
              Start Dictation
            </button>
            {(envName == "dev" || envName == "local") && (
              <>
                <button
                  className={`send-button ${
                    activeclicked && clickedButton === "verify insurance claim"
                      ? "green-class"
                      : ""
                  }`}
                  onClick={() => handleStaticButton("verify insurance claim")}
                  title="verify insurance claim"
                  disabled={
                    count >= limit ||
                    (clickedButton !== "" &&
                      clickedButton !== "verify insurance claim") ||
                    (activeclicked &&
                      clickedButton === "verify insurance claim")
                  }
                >
                  Verify Insurance Claim
                </button>
                <button
                  className={`send-button ${
                    activeclicked && clickedButton === "insurance claim"
                      ? "green-class"
                      : ""
                  }`}
                  onClick={() => handleStaticButton("insurance claim")}
                  disabled={
                    count >= limit ||
                    (clickedButton !== "" &&
                      clickedButton !== "insurance claim") ||
                    (activeclicked && clickedButton === "insurance claim")
                  }
                  title="Insurance Claim"
                >
                  Insurance Claim Generated by AI
                </button>
                <button
                  className={`send-button ${
                    activeclicked && clickedButton === "add denial reason"
                      ? "green-class"
                      : ""
                  }`}
                  onClick={() => handleStaticButton("add denial reason")}
                  title="add denial reason"
                  disabled={
                    count >= limit ||
                    (clickedButton !== "" &&
                      clickedButton !== "add denial reason") ||
                    (activeclicked && clickedButton === "add denial reason")
                  }
                >
                  Add denial reason
                </button>
              </>
            )}
            <button
              className={`exist-button ${
                isButtonDisabled ? "disabled-class" : "enabled-class"
              }`}
              // className="send-button"
              onClick={handleExitChat}
              disabled={count >= limit && isButtonDisabled}
              title="Start new Conversation"
            >
              Start new Conversation
            </button>
            {/* <div className="send-button">
            <button className="send-button-stop" onClick={isRecording ? stopRecording : startRecording}>
              {isRecording ? 'Stop Dictation' : 'Start Dictation'}
            </button>
          </div> */}
            {/* <img
            id="volume-wave"
            src="../../assets/images/Audio Wave GIF.gif" // Adjust the path as necessary
            alt="wave"
            className={`logo ${isRecording ? 'visible' : 'hidden'}`}
          /> */}
          <button
              className={`send-button`}
              // className="send-button"
              onClick={()=>{navigate('/log-monitoring')}}
              title="LogMonitoring"
            >
              LogMonitoring
            </button>
          </div>
          <div className="chat-input-container">
            <div className="input-with-mic">
              <input
                type="text"
                placeholder="Type your message..."
                value={response}
                onChange={(e) => setResponse(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                className="chat-input"
                disabled={count >= limit || threadButtonClicked}
              />
              <button
                onClick={startSpeechRecognition}
                className="mic-button"
                title="Mic"
              >
                {isMicrophoneOn ? (
                  <FontAwesomeIcon
                    icon={faMicrophone}
                    style={{ fontSize: "1.5em", color: "#1c84c6" }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faMicrophoneSlash}
                    style={{ fontSize: "1.5em", color: "#1c84c6" }}
                  />
                )}
              </button>
            </div>
            <button
              onClick={handleSendMessage}
              className="send-button"
              disabled={count >= limit || threadButtonClicked}
              title="Send"
            >
              Send
            </button>
            <button
              type="submit"
              onClick={resetAllData}
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                color: "blue",
                display: "flex",
                alignItems: "center",
              }}
              disabled={count >= limit}
              title="Reset"
            >
              <FiRefreshCw
                size={20}
                style={{ marginRight: "5px", color: "#1c84c6" }}
              />
            </button>
          </div>

          <div className="ssttag">
            <img src={sstlogo} alt="SSTAI logo" className="logo" />
            <span style={{ opacity: 1 }}>SSTAI IP</span>
            {/* <p>Demo</p>
            <p>mic:{micflag}</p> */}
          </div>
        </div>
        <div className="nav-bar">
          <button 
            onClick={toggleTranscript}
            className="toggle-button"
            title={isTranscriptOpen ? "Close Sidebar" : "Open Sidebar"}
            >
            <FontAwesomeIcon
              className="icon"
              icon={isTranscriptOpen ? faChevronRight : faChevronLeft }
            />
          </button>
          <div
            style={{
            display: isTranscriptOpen ? "block" : "none",
            alignItems: "center",
            color: "white",
            width: "250px",
          }}
        >
            {live_transcript}
            {isListening && <p>Porcupine is listening...</p>}
            {error && <p>Error: {error.message}</p>}
            {detectedKeyword && <p>Detected Keyword: {detectedKeyword}</p>}
            {isChatActive && "Bot is listening...."}
        </div>
        </div>
      </div>
    </div>
  );
};

export default SSTAI;
