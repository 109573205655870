import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Login from "./Login";
import SSTAI from "./SSTAI";
import LogMonitoring from "./Components/LogMonitoring/LogMonitoring";

function App() {
  return (
    //   <div className="App"></div>
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/SSTAI" element={<SSTAI />} />
        <Route path="/log-monitoring" element={<LogMonitoring/>}/>
      </Routes>
    </Router>
  );
}

export default App;
