import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMicrophone,
    faMicrophoneSlash,
    faRobot,
    faUser
} from "@fortawesome/free-solid-svg-icons";
import './ChatWindow.css'
import { Modal, Form } from 'react-bootstrap';
import axios from 'axios';
import Swal from 'sweetalert2'




const ChatWindow = ({ data }) => {
    const [isMicrophoneOn, setIsMicrophoneOn] = useState(false)
    const [showModal, setShowModal] = useState(false);


    const handleClick = (e) => {
        e.preventDefault();
        const input = document.getElementById('prompt');
        data.setUserPrompt(input.value)
        data.setSendMessage(true)
        input.value = ''

    }

    const handleNewLog = () => {
        setShowModal(true)
    }

    const handleClose = () => {
        setShowModal(false)
    }

    const [formData, setFormData] = useState({
        timeStamp: '',
        applicationName: '',
        errorType: '',
        errorMsg: '',
        reqMsg: '',
        reqPayload: '',
        responseStatusCode: null
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value
        }));
    };

    const handleFileUpload = () => {
        document.getElementById('fileInput').click();
    }

    const handleFileChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = async (event) => {
                const fileContent = event.target.result;
                console.log('File content:', fileContent);
                const parsedData = parseFileContent(fileContent);
                try {
                    const res = await axios.post('http://127.0.0.1:5000/addAllLog', parsedData);
                    console.log('File uploaded:', res.data);
                    if (res.status == 201) {
                        Swal.fire({
                            text: 'File uploaded successfully',
                            confirmButtonText: 'Ok'
                        })
                    }
                } catch (error) {
                    console.log('Error uploading file:', error);
                }
            };
            reader.readAsText(file);
        }
    };

    const parseFileContent = (content) => {
        const lines = content.split('\n').filter(line => line.trim() !== '');
        const headers = lines[0].split(',').map(header => header.trim());
        const data = lines.slice(1).map(line => {
            const values = line.split(/,(?=(?:(?:[^"]*"){2})*[^"]*$)/).map(value => value.trim());
            const record = {};
            headers.forEach((header, index) => {
                record[header] = values[index];
            });
            return record;
        });
        return data;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const res = await axios.post('http://127.0.0.1:5000/addLog', formData);
            if (res.status == 201) {
                Swal.fire({
                    text: 'Record added successfully.',
                    confirmButtonText: 'Ok'
                })
            }
            setShowModal(false);
            setFormData({
                timeStamp: '',
                applicationName: '',
                errorType: '',
                errorMsg: '',
                reqMsg: '',
                reqPayload: '',
                responseStatusCode: null
            });
        } catch (error) {
            console.log('Error adding record:', error);
        }
    };


    return (
        <div className="col-10 chatWindow mt-10 p-0">
            <div className='card px-3 pt-4 overflow-auto'>
                <div className='message-box-bot p-3 rounded'><span className='px-2'><FontAwesomeIcon icon={faRobot} /></span>Hi User, I am VAA. How can I help you?</div>
                {data.sendMessage && <div className='message-box-user p-3 rounded mt-4'><span className='px-2'><FontAwesomeIcon icon={faUser} /></span>{data.userPrompt}</div>
                }
                {data.chat && data.chat.map((item) => {
                    return (
                        <div className='message-box-bot p-3 rounded mt-4'>
                            <span className='px-2'><FontAwesomeIcon icon={faRobot} /></span>
                            {item.split('\n').map((line, index) => (
                                <div key={index}>{line}</div>
                            ))};
                        </div>)
                })}
            </div>
            <div className='text-box m-2 bg-white pt-2'>
                <div className='mb-2 d-flex'>
                    <Button className='me-2' onClick={handleNewLog}>Enter New Log</Button>
                    <Button onClick={handleFileUpload}>Upload Log file</Button>
                    <input
                        id="fileInput"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={handleFileChange}
                    />
                </div>
                <input id="prompt" className='w-75' type='text' placeholder='Select use case from left nav and Enter the prompt...'/>
                <span className='mic-input' onClick={handleClick}>
                    <FontAwesomeIcon icon={isMicrophoneOn ? faMicrophone : faMicrophoneSlash} />
                </span>
                <Button disabled={data.index.length === 0} className={ data.index.length === 0 && 'bg-secondary border-secondary'} onClick={handleClick}>Send</Button>
            </div>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter new log details</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <Form onSubmit={handleSubmit}>
                        <Form.Group controlId="formTimeStamp">
                            <Form.Label>Timestamp</Form.Label>
                            <Form.Control
                                type="text"
                                name="timeStamp"
                                value={formData.timeStamp}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formApplicationName">
                            <Form.Label>Application Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="applicationName"
                                value={formData.applicationName}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formErrorType">
                            <Form.Label>Error Type</Form.Label>
                            <Form.Control
                                type="text"
                                name="errorType"
                                value={formData.errorType}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formErrorMsg">
                            <Form.Label>Error Message</Form.Label>
                            <Form.Control
                                type="text"
                                name="errorMsg"
                                value={formData.errorMsg}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formReqMsg">
                            <Form.Label>Request Message</Form.Label>
                            <Form.Control
                                type="text"
                                name="reqMsg"
                                value={formData.reqMsg}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formReqPayload">
                            <Form.Label>Request Payload</Form.Label>
                            <Form.Control
                                type="text"
                                name="reqPayload"
                                value={formData.reqPayload}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="formResponseStatusCode">
                            <Form.Label>Response Status Code</Form.Label>
                            <Form.Control
                                type="number"
                                name="responseStatusCode"
                                value={formData.responseStatusCode || ''}
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                        <div style={{ margin: "10px 0 10px 0", float: 'right' }}>
                            <Button onClick={handleClose} style={{ marginRight: '20px' }}>
                                Cancel
                            </Button>

                            <Button variant="primary" type="submit">
                                Submit
                            </Button>
                        </div>
                    </Form>

                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ChatWindow