const OPENAI_API = "https://openaitimesheet.azurewebsites.net/";
// const ACS_API = "https://app-acs-dev-eastus.azurewebsites.net/";
const ACS_API = "https://app-cog-acs-eastus-001.azurewebsites.net/";
const VERTEX_API = "https://app-videosummarize-eastus.azurewebsites.net/";
// const VERTEX_API = "http://127.0.0.1:5000/"
export const openaiEnv = {
  OPENAI_API: OPENAI_API,
  ACS_API: ACS_API,
  VERTEX_API: VERTEX_API,
};
