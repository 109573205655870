import React, { useState, useEffect } from 'react'
import axios from 'axios';
import './LeftNavBar.css'
import sstlogo from "../../assests/SST Logo 1.jpg";
import { Modal } from 'react-bootstrap';

const LeftNavBar = ({ setErrorMessage, setIndex }) => {

  const [data, setData] = useState([])
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await axios.get('http://127.0.0.1:5000/data')
        setData(res.data)
      } catch (error) {
        console.log('Error', error)
      }
    }
    fetchData()
  }, [])

  const handleLinkClick = (rec) => {
    setSelectedRecord(rec)
    setShowModal(true)
    setErrorMessage('')
  }
  const handleClose = () => {
    setShowModal(false)
    setSelectedRecord(null);
  }

  // useEffect(() => {
  //   const newSelectedCheckboxes = {};
  //   const newIndex = [];
  //   if (isSelectAllChecked) {
  //     data.forEach((rec, idx) => {
  //       if (!selectedCheckboxes[idx]) {
  //         newSelectedCheckboxes[idx] = true;
  //         newIndex.push(idx);
  //       }
  //     });
  //     setSelectedCheckboxes((prev) => ({
  //       ...prev,
  //       ...newSelectedCheckboxes,
  //     }));
  //     setIndex((prevIndex) => [
  //       ...prevIndex,
  //       ...newIndex.filter((idx) => !prevIndex.includes(idx)),
  //     ]);
  //   }else{
  //     setSelectedCheckboxes({})
  //     setIndex([])
  //   }
  // }, [ data]);

  const handleSelectAll = () => {
    setIsSelectAllChecked(!isSelectAllChecked);
  };

  useEffect(() =>{
    const newSelectedCheckboxes = {};
    const newIndex = [];
    console.log(isSelectAllChecked,"LLL")

    if (isSelectAllChecked === true ) {
      data.forEach((rec, idx) => {
        if (!selectedCheckboxes[idx]) {
          newSelectedCheckboxes[idx] = true;
          newIndex.push(idx);
        }
      });
      setSelectedCheckboxes((prev) => ({
        ...prev,
        ...newSelectedCheckboxes,
      }));
      setIndex((prevIndex) => [
        ...prevIndex,
        ...newIndex.filter((idx) => !prevIndex.includes(idx)),
      ]);
    }
    else if( isSelectAllChecked === false){
      setSelectedCheckboxes({})
      setIndex([])
    }

  },[isSelectAllChecked])
  console.log("isSelectAllChecked", isSelectAllChecked)

  const handleCheckboxChange = (index) => {
    setSelectedCheckboxes((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));

    if (!selectedCheckboxes[index]) {
      setIndex((prevIndex) => [...prevIndex, index]);
    } else {
      setIndex((prevIndex) => prevIndex.filter((i) => i !== index));
    }
 
  };
  console.log("data=", data)
  console.log("isSelectAllChecked", isSelectAllChecked)
  console.log("selectedCheckboxes", selectedCheckboxes)
  // const areAllSelected = Object.keys(selectedCheckboxes).length === data.length;
  return (
    <>
      <div className='leftNavContainer col-2'>
        <div className='d-flex  justify-content-center mb-3'>
          <img src={sstlogo} width={100} alt="SST Logo"></img>
        </div>
        <div className='selectAll d-flex'>
          <div class="form-check">
            <input className="form-check-input " type="checkbox" value="" id="flexCheckDefault" onChange={handleSelectAll}
              checked={isSelectAllChecked}></input>
            {/* <p className='pl-3'>Select All</p> */}
          </div>
        </div>
        {
          data?.map((rec, index) => {
            return (
              <div className='errorContainer'>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"  onChange={() => handleCheckboxChange(index)}
                  checked={selectedCheckboxes[index] || false}></input>
                  <p className="truncated-text" title={rec.ErrorMessage} onClick={() => handleLinkClick(rec)}>
                    <span className="visible-text">{rec?.ErrorMessage?.substring(0, 50)}</span>
                    <p className='dots'>{rec.ErrorMessage.length > 40 && '   ...'}</p>
                  </p>
                </div>
              </div>)
          })
        }
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Record Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedRecord && (
            <>
              {
                Object.keys(selectedRecord).map((key, index) => {
                  console.log(key,index, selectedRecord[key],"LL")
                  return (<p className='overflow-x-auto' key={`record-${index}`}><strong>{key}: </strong>{JSON.stringify(selectedRecord[key])}</p>)
                })
              }
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default LeftNavBar